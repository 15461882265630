import { Table, Flex, Input } from "antd";
import { IoCreateOutline, IoTrashOutline, IoSearch } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import "./style.css"
import axios from "axios";
import { useState, useEffect } from "react";
import { UserManager } from "../../utils/tools/user-manager/UserManager";
import DeleteConfirm from "../delete-confirm/DeleteConfirm";

const apiUrl = window.AppConfig.API_ENDPOINT

const DealershipTable = ({data, setFiltersApi, pageInfo, setTrigger}) => {
    const [tableData, setTableData] = useState(data)
    const [searchQuery, setSearchQuery] = useState('');
    const [loggedUser, setLoggedUser] = useState(null);

    useEffect(() => {
        const user = UserManager();
        if (user) {
            setLoggedUser(user);
        }
    }, []);

    useEffect(() => {
        setTableData(data)
    }, [data])

    useEffect(() => {
        if (searchQuery.length > 2) {
            setFiltersApi(prevState => ({
                ...prevState, 
                Search: searchQuery
            }));
        }

        if (searchQuery.length === 0) {
            setFiltersApi(prevState => ({
                ...prevState, 
                Search: ""
            }));
        } 
    }, [searchQuery])

    const handleChange = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchQuery(value);

        const filteredData = data.filter(item => 
            Object.values(item).some(val =>
                val && val.toString().toLowerCase().includes(value)
            )
        );

        setTableData(filteredData);
    };

    const navigate = useNavigate();

    const deleteEntity = (id) => {
        if (loggedUser) {
            axios.delete(`${apiUrl}/api/v1/concessionarie/delete`, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                },
                data: { id: parseInt(id) } 
            })
            .then(response => {
                setTrigger(new Date());
            })
            .catch(error => {
                console.error('Error:', error);
            });
        }
    }

    const columns = [
        {
            title: 'Full name',
            dataIndex: 'full_name',
            className: "name-col",
            width: 250,
            render: (row, elem) => <a className="name-col" href={`/dealership/${elem.id}`}>{elem.nome} {elem.cognome}</a>
        },
        {
            title: 'Partner name',
            dataIndex: 'nomePartner',
            width: 200,
        },
        {
            title: 'Pec Email',
            dataIndex: 'emailPec',
        },
        {
            title: 'Phone Number',
            dataIndex: 'telefono',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (row, elem) => elem.status === "attivo" ? <div className="active-col">Attivo</div> : <div className="inactive-col">Disattivo</div>
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (row, elem) => <Flex gap="small" className="action-menu">
                    <IoCreateOutline size={20} onClick={() => navigate(`/dealership/${elem.id}`)} />
                    <DeleteConfirm action={deleteEntity} id={elem.utentiId} />
                </Flex>
        }
    ];

    const rowClassName = (record, index) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };

    const totalElements = pageInfo?.totalItems;
        
    return (
        <>
            <Input value={searchQuery} onChange={handleChange} className="table-search" size="large" placeholder="Ricerca" prefix={<IoSearch />} />
            <Table
                rowKey="id"
                columns={columns}
                dataSource={tableData}
                pagination={{
                    pageSize: pageInfo?.pageSize,
                    total: totalElements,
                }}
                scroll={{y: "100%", x: 1300}}
                rowClassName={rowClassName}
                pageSize={pageInfo?.pager?.totalPages}
                onChange={(data) => {
                    setFiltersApi(prevState => ({
                        ...prevState, 
                        Page: data.current,
                        Size: data.pageSize
                    }));
                }}
            />
        </>
    )
}

export default DealershipTable;