import Navigation from "../components/navigation/Navigation";
import Layout from "antd/es/layout/layout";
import { Flex, Card, Form, Input, Breadcrumb } from "antd";
import { IoHome } from "react-icons/io5";
import axios from 'axios';
import { useState, useEffect } from "react";
import Loading from '../components/loading/Loading';
import { UserManager } from "../utils/tools/user-manager/UserManager";
import { notify } from "../utils/tools/Tools"

const { Header } = Layout;

const apiUrl = window.AppConfig.API_ENDPOINT

const Account = () => {
    const [data, setData] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [loggedUser, setLoggedUser] = useState(null);

    useEffect(() => {
        const user = UserManager();
        if (user) {
            setLoggedUser(user);
        }
    }, []);

    const editInfo = (values) => {
        if (loggedUser) {
            axios.post(`${apiUrl}/api/v1/profile/upsert`, {...values, id: data?.id}, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
                .then(response => {
                    notify("Dati salvati con successo")
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    };

    const editPassword = (values) => {
        if (loggedUser) {
            axios.post(`${apiUrl}/api/v1/profile/change-pwd`, {password: values.password, id: data?.id}, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
                .then(response => {
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    };

    useEffect(() => {
        if (loggedUser) {
            axios.post(`${apiUrl}/api/v1/profile/get-detail`, {}, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
                .then(response => {
                    setData(response.data)
                    setIsLoading(false)
                })
                .catch(error => {
                    console.error('Error:', error);
                    setIsLoading(false)
                });
        }
    }, [loggedUser])
    
    return (
        <>
            <Loading visible={isLoading} />
            <Navigation>
                <Header>
                    <Flex justify="space-between" align="center">
                        <div>
                            <div className="main-title">Account</div>
                        </div>
                    </Flex>
                </Header>
                <div className="main">
                    <Breadcrumb
                        separator=">"
                        items={[
                        {
                            href: "/",
                            title: (
                                <Flex gap="small" align="center">
                                    <IoHome />
                                    Home
                                </Flex>
                            ),
                        },
                        {
                            title: 'Account',
                            className: 'active-breadcrumb'
                        }
                        ]}
                    />
                    <Card title="Anagrafica" className="form-card">
                        <Flex vertical gap="large">
                            <Form
                                layout="inline"
                                name="anagrafica"
                                initialValues={{
                                    remember: true,
                                }}
                                validateMessages={{required: false}}
                                onFinish={editInfo}
                                fields={[
                                    {
                                        name: ["nome"],
                                        value: data?.nome
                                    },
                                    {
                                        name: ["cognome"],
                                        value: data?.cognome
                                    },
                                    {
                                        name: ["email"],
                                        value: data?.email
                                    }
                                ]}
                            >
                                <Flex vertical gap="large" style={{width: "100%"}}>
                                    <Flex>
                                        <Form.Item
                                            name="nome"
                                            style={{width: "33%"}}
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Name" />
                                        </Form.Item>

                                        <Form.Item
                                            name="cognome"
                                            style={{width: "33%"}}
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Surname" />
                                        </Form.Item>

                                        <Form.Item
                                            name="email"
                                            style={{width: "32%"}}
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Email" disabled />
                                        </Form.Item>
                                    </Flex>
                                    <Flex justify="right" align="center">
                                        <button htmltype="submit" className="save-button">Salva</button>
                                    </Flex>
                                </Flex>
                                
                            </Form>
                        </Flex>
                    </Card>
                    <Card title="Modifica Password" className="form-card">
                        <Form
                            layout="inline"
                            name="password"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={editPassword}
                        >
                            <Flex vertical gap="large" style={{width: "100%"}}>
                                <Flex>
                                    <Form.Item
                                        name="password"
                                        style={{width: "49%"}}
                                        rules={[
                                        {
                                            required: true,
                                        },
                                        ]}
                                        hasFeedback
                                    >
                                        <Input.Password placeholder="Password" />
                                    </Form.Item>

                                    <Form.Item
                                        name="confirm"
                                        dependencies={['password']}
                                        hasFeedback
                                        style={{width: "50%"}}
                                        rules={[
                                        {
                                            required: true,
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The new password that you entered do not match!'));
                                            },
                                        }),
                                        ]}
                                    >
                                        <Input.Password placeholder="Confirm Password"/>
                                    </Form.Item>
                                </Flex>
                                <Flex justify="right" align="center">
                                    <button className="save-button">Salva</button>
                                </Flex>
                            </Flex>
                        </Form>
                    </Card>
                </div>
            </Navigation>
        </>
    )
}

export default Account;