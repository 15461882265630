import { useState, useEffect } from 'react';
import { Input, Flex, Drawer, Collapse, Card, Row, Col, Select, Breadcrumb, Pagination } from 'antd';
import Navigation from '../../components/navigation/Navigation';
import Layout from 'antd/es/layout/layout';
import { FaListUl } from "react-icons/fa";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useNavigate } from "react-router-dom";
import { IoCloseCircleOutline, IoFilter, IoHome, IoSearch, IoAddCircle } from 'react-icons/io5';
import { UserManager } from '../../utils/tools/user-manager/UserManager';
import axios from 'axios';
import "./style.css"
import Loading from '../../components/loading/Loading';

dayjs.extend(relativeTime);

const { Header } = Layout;

const apiUrl = window.AppConfig.API_ENDPOINT

const Playlist = ({mode}) => {
    const [dataset, setDataset] = useState([])
    const [filtersApi, setFiltersApi] = useState({
        "Search":"",
        "Titolo":"",
        "CategorieId":[],
        "TagsId":[],
        "Page": 1
    })      
    const [pager, setPager] = useState()
    const [searchQuery, setSearchQuery] = useState('');
    const [open, setOpen] = useState(false);
    const [sidebarFilters, setSidebarFilters] = useState({tag: [], category: []})
    const [tag, setTag] = useState([])
    const [category, setCategory] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [loggedUser, setLoggedUser] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const user = UserManager();
        if (user) {
            setLoggedUser(user);
        }
    }, []);

    useEffect(() => {
        if (loggedUser) {
            axios.post(`${apiUrl}/api/v1/playlist/get-list`, filtersApi, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
                .then(response => {
                    setDataset(response.data.data);
                    setPager(response.data.pager);
                    setIsLoading(false);
                })
                .catch(error => {
                    console.error('Error:', error);
                    setIsLoading(false);
                });

            axios.post(`${apiUrl}/api/v1/tagcategorie/get-list-categorie`, {}, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
                .then(response => {
                    const categories = response.data.data.map((r) => ({label: r.nome, value: r.id}))
                    setCategory(categories)
                })
                .catch(error => {
                    console.error('Error:', error);
                });

            axios.post(`${apiUrl}/api/v1/tagcategorie/get-list-tags`, {}, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
                .then(response => {
                    const tags = response.data.data.map((r) => ({label: r.nome, value: r.id}))
                    setTag(tags)
                })
                .catch(error => {
                    console.log('Error:', error);
                });
        }
    }, [filtersApi, loggedUser])

    const setFiltersSidebar = (type, values) => {
        switch (type) {
            case "tag": setSidebarFilters(prevState => ({
                ...prevState, tag: values}))
                break;
            case "category": setSidebarFilters(prevState => ({
                ...prevState, category: values}))
                break;
            case "apply": handleFilter()
                break;
        }
    };

    const handleFilter = () => {
        setIsLoading(true)
        setOpen(false)
        setFiltersApi(prevState => ({
            ...prevState, 
            CategorieId: sidebarFilters.category,
            TagsId: sidebarFilters.tag,
        }));
    }

    const handleChange = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchQuery(value);
    };

    useEffect(() => {
        if (searchQuery.length > 2) {
            setFiltersApi(prevState => ({
                ...prevState, 
                Search: searchQuery
            }));
        }

        if (searchQuery.length === 0) {
            setFiltersApi(prevState => ({
                ...prevState, 
                Search: ""
            }));
        } 
    }, [searchQuery])

    const items = [
        {
            key: '1',
            label: 'Categorie',
            children: <Select
                optionFilterProp="label"
                mode="multiple"
                allowClear
                style={{
                    width: '100%',
                }}
                placeholder="Select category"
                onChange={(values) => setFiltersSidebar("category", values)}
                options={category}
            />,
        },
        {
            key: '2',
            label: 'Tag',
            children: <Select
                optionFilterProp="label"
                mode="multiple"
                allowClear
                style={{
                    width: '100%',
                }}
                placeholder="Select tags"
                onChange={(values) => setFiltersSidebar("tag", values)}
                options={tag}
            />,
        },
    ];

    const pageChange = (event) => {
        setCurrentPage(event)
        setFiltersApi(prevState => ({
            ...prevState, 
            Page: event
        }));
    }

    return (
        <>
            <Loading visible={isLoading} />
            <Drawer width={500} className="filter-drawer" title="Filtri" open={open} onClose={() => setOpen(false)} closeIcon={<IoCloseCircleOutline size={28}/>}>
                <Collapse className="custom-collapse" items={items} expandIconPosition="end"
                    expandIcon={({ isActive }) => isActive ? <img alt="active-accordion" src="/custom-icons/CloseAccordion.svg" /> : <img alt="active-accordion" src="/custom-icons/OpenAccordion.svg" />}
                />
                <Flex justify="right" align="center">
                    <button onClick={() => setFiltersSidebar("apply", null)} className="save-button w-100">Applica</button>
                </Flex>
            </Drawer>
            <Navigation>
                <Header>
                    <Flex justify="space-between" align="center">
                        <div>
                            <div className="main-title">Playlist</div>
                        </div>
                        <div className="filter-section">
                            <button onClick={() => navigate("/playlist/new")} className="add-button">
                                Aggiungi playlist
                                <IoAddCircle size={15} />
                            </button>
                        </div>
                    </Flex>
                </Header>
                <div className="main">
                    <Breadcrumb
                        separator=">"
                        items={[
                        {
                            href: "/",
                            title: (
                                <Flex gap="small" align="center">
                                    <IoHome />
                                    Home
                                </Flex>
                            ),
                        },
                        {
                            title: 'Playlist',
                            className: 'active-breadcrumb'
                        },
                        ]}
                    />
                    <Flex align="center" gap="large" className="filter-section no-padding">
                        <Input value={searchQuery} onChange={handleChange} placeholder="Cerca" style={{ width: '98%', background: "#F7F8F3", height: "50px"}} prefix={<IoSearch />} />
                        <button className="filter" onClick={() => setOpen(true)}>
                            <IoFilter />
                            Filtri
                        </button>
                    </Flex>

                    <Row gutter={[68, 46]} className="card-container">
                        {dataset.map((data) => (
                            <Col
                                xs={24}      // 1 column for extra small screens
                                sm={24}      // 2 columns for small screens
                                md={12}       // 2 columns for medium screens
                                lg={12}       // 3 columns for large screens
                                xl={8}       // 3 columns for extra large screens
                                key={`playlist-${data.id}`}
                            >
                                <Card
                                    className="video-card"
                                    onClick={() => navigate(`/playlist/${data.id}`)}
                                    cover={
                                        <div className="image-container">
                                            <img alt="cover" src={data.copertina} className="video-image" />
                                            <div className="overlay">
                                                <Flex vertical className="icon">
                                                    {data.video.length}
                                                    <FaListUl className="playlist-icon"/>
                                                </Flex>
                                            </div>
                                        </div>
                                    }
                                >
                                    <Flex justify='space-between' align='center'>
                                        <span className="card-title">{data.titolo}</span>
                                    </Flex>
                                    <span className="card-time">{dayjs(data.dtCreazione).fromNow()}</span>
                                    <div className="tag-section">
                                        {data.tags.map(item => tag.find(t => t.value === item)?.label)}
                                    </div>
                                </Card>
                            </Col>
                        ))}
                    </Row>

                    <Pagination style={{paddingTop: 30}} defaultPageSize={20} current={currentPage} onChange={pageChange} total={pager?.totalItems} />
                    
                </div>
                
            </Navigation>
        </>
    )
}

export default Playlist;