import { useState, useEffect } from "react";
import Navigation from "../../components/navigation/Navigation";
import Layout from "antd/es/layout/layout";
import { Flex, Form, Select, Input, Collapse, Card, InputNumber, Button, Breadcrumb, TreeSelect, Divider } from 'antd';
import "./style.css"
import { useNavigate, useParams } from "react-router-dom";
import { IoHome } from "react-icons/io5";
import axios from "axios";
import Loading from "../../components/loading/Loading";
import { convertStringFloat, convertFloat } from "../../utils/tools/Tools";
import BackButton from "../../components/navigation/BackButton";
import { UserManager } from "../../utils/tools/user-manager/UserManager";

const { Header } = Layout;

const apiUrl = window.AppConfig.API_ENDPOINT

const Website = ({ data, treeData, form, typeSelected }) => {
    const { id } = useParams();

    let partnerOptions = [];

    if (data) {
        partnerOptions = [{ value: data.publisherInfoId, label: data.publisherInfoNome }];
    } else {
        partnerOptions = treeData;
    }

    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                nome: data?.nome,
                url: data?.url,
                status: data?.status,
                publisherInfoId: data?.publisherInfoId,
            });
        }
    }, [data, form]);

    return (
        <Form
            layout="inline"
            name="website"
            form={form}
            validateMessages={{required: false}}
            initialValues={{
                nome: data?.nome,
                url: data?.url,
                status: data?.status || "attivo",
                publisherInfoId: data?.publisherInfoId,
            }}
        >
            <Flex vertical gap="large" style={{ width: "100%" }}>
                <Flex>
                    <Form.Item
                        name="nome"
                        style={{ width: "24%" }}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input placeholder="Nome" />
                    </Form.Item>

                    <Form.Item
                        name="url"
                        style={{ width: "24%" }}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input placeholder="Sito" />
                    </Form.Item>
                    <Form.Item
                        name="publisherInfoId"
                        style={{ width: "24%" }}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <TreeSelect
                            showSearch
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            placeholder="Concessionaria/Partner"
                            disabled={id}
                            allowClear
                            treeDefaultExpandAll
                            treeData={partnerOptions}
                            onChange={(val) => typeSelected(val)}
                        />
                    </Form.Item>
                    <Form.Item
                        name="status"
                        style={{ width: "22%" }}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select
                            optionFilterProp="label"
                            showSearch
                            options={[
                                { value: "attivo", label: "Attivo" },
                                { value: "bozza", label: "Bozza" },
                                { value: "cancellato", label: "Cancellato" },
                            ]}
                            placeholder="Stato"
                            defaultValue={"attivo"}
                        />
                    </Form.Item>
                </Flex>
            </Flex>
        </Form>
    );
};

const Revenue = ({type, data, form, setRevenueValues, listId=false, keyElement, defaultCard=false}) => {
    const [value, setValue] = useState(
        data ? {
            percentuale: convertFloat(data?.percentuale),
            fissoECPM: convertFloat(data?.fissoECPM),
            percentualeParent: convertFloat(data?.percentualeParent),
            fissoECPMParent: convertFloat(data?.fissoECPMParent)
        } : {
            percentuale: convertFloat("0,00"),
            fissoECPM: convertFloat("0,00"),
            percentualeParent: convertFloat("0,00"),
            fissoECPMParent: convertFloat("0,00")
        }
    );

    // Aggiorna i valori quando `data` cambia
    useEffect(() => {
        if (data) {
            const newValue = {
                percentuale: convertFloat(data?.percentuale),
                fissoECPM: convertFloat(data?.fissoECPM),
                percentualeParent: convertFloat(data?.percentualeParent),
                fissoECPMParent: convertFloat(data?.fissoECPMParent)
            };
            setValue(newValue);
        }
    }, [data]);

    // Funzione per aggiornare lo stato per InputNumber
    const onChangeInput = (val, key) => {
        if (val === "" || val === undefined || isNaN(val) || val === null) {
            updateValue(0, key);
        } else {
            const numericalValue = parseFloat(val);
            updateValue(parseFloat(numericalValue.toFixed(2)), key);
        }
    };

    // Funzione per aggiornare lo stato tramite i bottoni
    const onChangeValueFromButton = (incrementValue, key) => {
        const currentValue = value[key] ?? 0;
        let newValue = parseFloat(currentValue) + incrementValue;
        newValue = Math.max(newValue, 0).toFixed(2);
        updateValue(parseFloat(newValue), key);
    };

    // Funzione per aggiornare il valore nello stato `value` e chiamare `setRevenueValues`
    const updateValue = (newValue, key) => {
        setValue(prev => ({ ...prev, [key]: newValue }));
        if (listId) {
            setRevenueValues(prev => ({ ...prev, [key]: newValue, id: listId }));
        } else {
            setRevenueValues(prev => ({ ...prev, [key]: newValue }));
        }
    };

    // Funzione per restituire lo stile dinamico in base al valore
    const getInputStyle = (key) => {
        return (value[key] === 0 && !defaultCard) ? { borderColor: 'red', color: 'red' } : {};
    };

    return (
        <Form
            layout="inline"
            name={`revenue ${type} ${keyElement}`}
            form={form}
            validateMessages={{required: false}}
            style={{width: "100%"}}
        >
            <Flex vertical gap="large" style={{width: "100%"}}>
                <Flex style={{width: "100%"}}>
                    <Flex vertical style={{flexGrow: 1}}>
                        <div style={{width: "100%"}}>
                            <Flex>
                                <h3>Fixed ECPM</h3>
                            </Flex>
                            <Form.Item
                                name="ecpm"
                                style={{width: "100%"}}
                                rules={[
                                    {
                                        validator(_, val) {
                                            if (val === 0) {
                                                return Promise.reject("Il valore non può essere 0");
                                            }
                                            return Promise.resolve();
                                        },
                                    },
                                ]}
                            >
                                <Flex style={{width: "100%"}}>
                                    <Button 
                                        className="value-change-button1" 
                                        onClick={() => onChangeValueFromButton(-0.01, type === 1 ? "fissoECPM" : "fissoECPMParent")}
                                    >
                                        -
                                    </Button>
                                    <InputNumber
                                        min={0}
                                        value={type === 1 ? value.fissoECPM : value.fissoECPMParent}
                                        onChange={(val) => onChangeInput(val, type === 1 ? "fissoECPM" : "fissoECPMParent")}
                                        className="input-number"
                                        formatter={value => `${value}`.replace(',', '.')}
                                        parser={value => value.replace(',', '.')}
                                        step={0.01}
                                        style={{width: "50%", ...getInputStyle(type === 1 ? "fissoECPM" : "fissoECPMParent")}}
                                    />
                                    <Button 
                                        className="value-change-button2" 
                                        onClick={() => onChangeValueFromButton(0.01, type === 1 ? "fissoECPM" : "fissoECPMParent")}
                                    >
                                        +
                                    </Button>
                                </Flex>
                            </Form.Item>
                        </div>
                    </Flex>
                    <Flex vertical style={{flexGrow: 1}}>
                        <div style={{width: "100%"}}>
                            <Flex>
                                <h3>Percentage</h3>
                            </Flex>
                            <Form.Item
                                name="percentage"
                                style={{width: "100%"}}
                                rules={[
                                    {
                                        validator(_, val) {
                                            if (val === 0) {
                                                return Promise.reject("Il valore non può essere 0");
                                            }
                                            return Promise.resolve();
                                        },
                                    },
                                ]}
                            >
                                <Flex style={{width: "100%"}}>
                                    <Button 
                                        className="value-change-button1" 
                                        onClick={() => onChangeValueFromButton(-0.01, type === 1 ? "percentuale" : "percentualeParent")}
                                    >
                                        -
                                    </Button>
                                    <InputNumber
                                        min={0}
                                        value={type === 1 ? value.percentuale : value.percentualeParent}
                                        onChange={(val) => onChangeInput(val, type === 1 ? "percentuale" : "percentualeParent")}
                                        className="input-number"
                                        formatter={value => `${value}`.replace(',', '.')}
                                        parser={value => value.replace(',', '.')}
                                        step={0.01}
                                        style={{width: "50%", ...getInputStyle(type === 1 ? "percentuale" : "percentualeParent")}}
                                    />
                                    <Button 
                                        className="value-change-button2" 
                                        onClick={() => onChangeValueFromButton(0.01, type === 1 ? "percentuale" : "percentualeParent")}
                                    >
                                        +
                                    </Button>
                                </Flex>
                            </Form.Item>
                        </div>
                    </Flex>
                </Flex>
            </Flex>
        </Form>
    )
};

const SourceRevenue = ({id, data, type, sourceList, setRevenueValuesList, entityDetails, loggedUser}) => {
    const [sourceDataset, setSourceDataset] = useState();
    const [sourceListFull, setSourceListFull] = useState();
    const [updatedValues, setUpdatedValues] = useState();
    const [activeKeys, setActiveKeys] = useState([]);

    useEffect(() => {
        if (!sourceListFull && sourceList && entityDetails) {
            const retrievedData = sourceList.filter((item) => entityDetails.fonti.includes(item.value));
            setSourceListFull(retrievedData);
        }
    }, [sourceList, entityDetails]);

    useEffect(() => {
        if (sourceListFull && !sourceDataset) {
            let result = [];
            if (data?.fonti.length === 0) {
                sourceListFull.forEach(item => {
                    result.push({
                        id: 0,
                        FonteId: item.value,
                        WebSiteId: parseInt(id),
                        label: item.label,
                        percentuale: "0,00",
                        fissoECPM: "0,00",
                        percentualeParent: "0,00",
                        fissoECPMParent: "0,00",
                        status: "attivo"
                    });
                });
            } else {
                result = data?.fonti;
                result.forEach(item => {
                    const label = sourceListFull.find(element => element.value === item.fonteId)?.label;
                    item.label = label;
                });
            }

            if (result.length < sourceListFull.length) {
                const notInserted = sourceListFull.filter(item2 => !result.some(item1 => item1.fonteId === item2.value));
                notInserted.forEach(item => {
                    result.push({
                        id: 0,
                        FonteId: item.value,
                        WebSiteId: parseInt(id),
                        label: item.label,
                        percentuale: "0,00",
                        fissoECPM: "0,00",
                        percentualeParent: "0,00",
                        fissoECPMParent: "0,00",
                        status: "attivo"
                    });
                });
            }

            setSourceDataset(result);
            setRevenueValuesList(result);

            // Imposto le chiavi attive in base allo stato "attivo"
            const initialActiveKeys = result
                .filter(item => item.status === "attivo")
                .map(item => `source-list-${item.FonteId ?? item.fonteId}`);
            setActiveKeys(initialActiveKeys);
        }
    }, [sourceListFull, data]);

    useEffect(() => {
        if (updatedValues) {
            let result;
            if (data?.fonti.length === 0) {
                result = sourceDataset.map(item => {
                    if (item.FonteId === updatedValues.id) {
                        let updatedItem = { ...item };
                        for (let key in updatedValues) {
                            if (key !== 'id' && updatedValues.hasOwnProperty(key)) {
                                updatedItem[key] = typeof updatedValues[key] === 'number' 
                                    ? updatedValues[key].toFixed(2).replace('.', ',') 
                                    : updatedValues[key];
                            }
                        }
                        return updatedItem;
                    }
                    return item;
                });
            } else {
                result = [...sourceDataset];
                const index = result.findIndex(item => item.fonteId === updatedValues.id);
                for (let key in result[index]) {
                    if (key !== 'id' && updatedValues[key]) {
                        result[index][key] = convertStringFloat(updatedValues[key]);
                    }
                }
            }

            setSourceDataset(result);
            setRevenueValuesList(result);
        }
    }, [updatedValues]);

    const statusSwitcher = (statusInfo, setStatusInfoArray) => {
        const data = {
            "WebSiteId": statusInfo.webSiteId ?? statusInfo.WebSiteId,
            "FonteId": statusInfo.fonteId ?? statusInfo.FonteId,
            "id": statusInfo.id,
            "status": statusInfo.status === "attivo" ? "inattivo" : "attivo",
        };

        axios.post(`${apiUrl}/api/v1/inventory/enable-fonti-revenue`, data, {
            headers: {
                'Authorization': `Bearer ${loggedUser.token.accessToken}`
            }
        })
            .then(response => {
                setStatusInfoArray(prevArray =>
                    prevArray.map(item =>
                        item.id === statusInfo.id
                        ? { ...item, status: statusInfo.status === "attivo" ? "inattivo" : "attivo" }
                        : item 
                    )
                );

                // Aggiorno activeKeys dopo il cambio di stato
                setActiveKeys(prevKeys => {
                    const key = `source-list-${statusInfo.FonteId ?? statusInfo.fonteId}`;
                    if (statusInfo.status === "attivo") {
                        return prevKeys.filter(k => k !== key);
                    } else {
                        return [...prevKeys, key];
                    }
                });
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    console.log(sourceDataset);
    return (
        <Flex gap={30} vertical style={{ textAlign: "center" }}>
            {sourceDataset?.map(element => (
                <Collapse
                    collapsible="disabled"
                    key={`source-list-${element.FonteId ?? element.fonteId}`}
                    className="form-collapse revenue-collapse"
                    activeKey={activeKeys} // Uso activeKey invece di defaultActiveKey
                    items={[
                        {
                            showArrow: false,
                            key: `source-list-${element.FonteId ?? element.fonteId}`,
                            label: (
                                <Flex justify="space-between">
                                    <div style={{
                                        color: element.status === "attivo" ? "#CE2472" : "black"
                                    }}>
                                        {element.label}
                                    </div>
                                    <div onClick={(e) => {
                                        e.stopPropagation();
                                        statusSwitcher(element, setSourceDataset);
                                    }}>
                                        {element.status === "attivo" ?
                                            <div className="inactive-col">Disattiva</div> :
                                            <div className="active-col">Attiva</div>
                                        }
                                    </div>
                                </Flex>
                            ),
                            children: (
                                <Flex gap="small">
                                    {type === "dealership" ? (
                                        <Card key={`multiple-${element.label}-1`} className="inventory-card" title="Revenue Publisher">
                                            <Revenue
                                                keyElement={`multiple-${element.label}-1`}
                                                type={1}
                                                data={{
                                                    percentuale: element.percentuale,
                                                    fissoECPM: element.fissoECPM,
                                                    percentualeParent: element.percentualeParent,
                                                    fissoECPMParent: element.fissoECPMParent,
                                                }}
                                                setRevenueValues={setUpdatedValues}
                                                listId={element.FonteId ?? element.fonteId}
                                            />
                                        </Card>
                                    ) : type === "publisher" ? (
                                        <>
                                            <Card key={`multiple-${element.label}-1`} className="inventory-card" title="Revenue Publisher">
                                                <Revenue
                                                    keyElement={`multiple-${element.label}-1`}
                                                    type={1}
                                                    data={{
                                                        percentuale: element.percentuale,
                                                        fissoECPM: element.fissoECPM,
                                                        percentualeParent: element.percentualeParent,
                                                        fissoECPMParent: element.fissoECPMParent,
                                                    }}
                                                    setRevenueValues={setUpdatedValues}
                                                    listId={element.FonteId ?? element.fonteId}
                                                />
                                            </Card>
                                            <Card key={`multiple-${element.label}-2`} className="inventory-card" title="Revenue Concessionaria">
                                                <Revenue
                                                    keyElement={`multiple-${element.label}-2`}
                                                    type={2}
                                                    data={{
                                                        percentuale: element.percentuale,
                                                        fissoECPM: element.fissoECPM,
                                                        percentualeParent: element.percentualeParent,
                                                        fissoECPMParent: element.fissoECPMParent,
                                                    }}
                                                    setRevenueValues={setUpdatedValues}
                                                    listId={element.FonteId ?? element.fonteId}
                                                />
                                            </Card>
                                        </>
                                    ) : null}
                                </Flex>
                            ),
                            hidden: false
                        }
                    ]}
                />
            ))}
        </Flex>
    );
};

const generateNestedList = (parents, children) => {
    const nestedList = parents.map(parent => {
        const matchingChildren = children.filter(child => child.text1 === parent.value);
        
        const result = {
            value: parseInt(parent.value),
            title: parent.text,
            children: matchingChildren.map(child => ({
                value: parseInt(child.value),
                title: child.text
            }))
        };
        
        return result;
    });

    return nestedList;
};

const NewInventory = () => {
    const { id } = useParams();

    const [dataset, setDataset] = useState()
    const [dealershipList, setDealershipList] = useState([])
    const [publisherList, setPublisherList] = useState([])
    const [fullList, setFullList] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [form] = Form.useForm();
    const [revenueValues, setRevenueValues] = useState({
        "fissoECPM": 0.00,
        "percentuale": 0.00,
        "fissoECPMParent": 0.00,
        "percentualeParent": 0.00
    })
    const [revenueValuesList, setRevenueValuesList] = useState([])
    const [loggedUser, setLoggedUser] = useState(null);
    const [typeSelected, setTypeSelected] = useState()
    const [cardShowerByType, setCardShowerByType] = useState()
    const [currentType, setCurrentType] = useState()
    const [sourceList, setSourceList] = useState()
    const [entityDetails, setEntityDetails] = useState()

    useEffect(() => {
        const user = UserManager();
        if (user) {
            setLoggedUser(user);
        }
    }, []);

    const handleSubmit = () => {
        if (loggedUser) {
            const parsedRevenues = {
                percentuale: convertStringFloat(revenueValues.percentuale),
                fissoECPM: convertStringFloat(revenueValues.fissoECPM),
                percentualeParent: convertStringFloat(revenueValues.percentualeParent),
                fissoECPMParent: convertStringFloat(revenueValues.fissoECPMParent)
            }
            form.validateFields()
            .then(values => {
                const data = {
                    id: id ? parseInt(id) : 0, ...parsedRevenues, ...values, fonti: revenueValuesList
                }

                data.fonti.map(d => delete d.status)

                const zeroToCheck = (obj) => cardShowerByType === "dealership" ? obj.fissoECPM === "0,00" || obj.percentuale === "0,00" : 
                                obj.fissoECPMParent === "0,00" || obj.percentualeParent === "0,00"

                const hasValueZeroAndActive = data.fonti.some(obj =>
                    zeroToCheck(obj) && obj.status === "attivo");

                if (hasValueZeroAndActive) {
                    return false
                }

                data.fonti.map(item => {
                    delete item.label;
                    item.userCreazione = loggedUser.user.id
                })

                axios.put(`${apiUrl}/api/v1/inventory/upsert`, data, {
                    headers: {
                        'Authorization': `Bearer ${loggedUser.token.accessToken}`
                    }
                })
                .then(response => {
                    navigate(`/inventory`)
                })
                .catch(error => {
                    console.error('Error:', error);
                });
            })
            .catch(errorInfo => {
                console.log('Error:', errorInfo);
            });
        }
    }

    useEffect(() => {
        if (dealershipList.length > 0) {
            const nestedList = generateNestedList(dealershipList, publisherList);
            setFullList(nestedList);
        }
    }, [dealershipList, publisherList]);

    const navigate = useNavigate();
    
    useEffect(() => {
        if (loggedUser) {
            if (id) {
                axios.post(`${apiUrl}/api/v1/inventory/get-detail`, {id: parseInt(id)}, {
                    headers: {
                        'Authorization': `Bearer ${loggedUser.token.accessToken}`
                    }
                })
                    .then(response => {
                        setDataset(response.data)
                        setIsLoading(false)
                    })
                    .catch(error => {
                        console.error('Error:', error);
                        setIsLoading(false)
                    });
            }

            axios.post(`${apiUrl}/api/v1/init/list-fonti`, {}, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
            .then(response => {
                const data = response.data.map(item => ({value: parseInt(item.value), label: item.text}))
                setSourceList(data)
            })
            .catch(error => {
                console.error('Error:', error);
            });

            axios.post(`${apiUrl}/api/v1/init/list-concessionarie`, {}, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
                .then(response => {
                    setDealershipList(response.data)
                })
                .catch(error => {
                    console.error('Error:', error);
                });
    
            axios.post(`${apiUrl}/api/v1/init/list-publishers`, {}, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
                .then(response => {
                    setPublisherList(response.data)
                })
                .catch(error => {
                    console.error('Error:', error);
                });
            setIsLoading(false)
        }
    }, [loggedUser])

    useEffect(() => {
        if (typeSelected) {
            const publisher = publisherList.find(item => parseInt(item.value) === typeSelected)
            const dealership = dealershipList.find(item => parseInt(item.value) === typeSelected)
            if (publisher) {
                setCardShowerByType("publisher")
                setCurrentType(publisher)
            }
    
            if (dealership) {
                setCardShowerByType("dealership")
                setCurrentType(dealership)
            }
        }

        if (dataset) {
            const publisher = publisherList.find(item => parseInt(item.value) === dataset.publisherInfoId)
            const dealership = dealershipList.find(item => parseInt(item.value) === dataset.publisherInfoId)
            if (publisher) {
                setCardShowerByType("publisher")
                setCurrentType(publisher)
            }
    
            if (dealership) {
                setCardShowerByType("dealership")
                setCurrentType(dealership)
            }
        }
    }, [typeSelected, dataset])

    useEffect(() => {
        if (currentType) {
            setIsLoading(true); // Set loading state before making the API call
    
            if (cardShowerByType === "dealership") {
                axios.post(`${apiUrl}/api/v1/concessionarie/get-detail`, {id: parseInt(currentType.value)}, {
                    headers: {
                        'Authorization': `Bearer ${loggedUser.token.accessToken}`
                    }
                })
                .then(response => {
                    setEntityDetails(response.data);
                    setIsLoading(false); // Set loading to false when data is fetched
                })
                .catch(error => {
                    console.error('Error:', error);
                    setIsLoading(false); // Set loading to false even on error
                });
            }
    
            if (cardShowerByType === "publisher") {
                axios.post(`${apiUrl}/api/v1/publisher/get-detail`, {id: parseInt(currentType.value)}, {
                    headers: {
                        'Authorization': `Bearer ${loggedUser.token.accessToken}`
                    }
                })
                .then(response => {
                    let data = response.data;
                    const idDealershipConnected = publisherList.find(publisher => publisher.value === currentType.value).text1;
                    axios.post(`${apiUrl}/api/v1/concessionarie/get-detail`, {id: parseInt(idDealershipConnected)}, {
                        headers: {
                            'Authorization': `Bearer ${loggedUser.token.accessToken}`
                        }
                    })
                    .then(responseDealership => {
                        data.fonti = responseDealership.data.fonti;
                        setEntityDetails(data);
                        setIsLoading(false); // Set loading to false when data is fetched
                    })
                    .catch(error => {
                        console.error('Error:', error);
                        setIsLoading(false); // Set loading to false even on error
                    });
                })
                .catch(error => {
                    console.error('Error:', error);
                    setIsLoading(false); // Set loading to false even on error
                });
            }
        }
    }, [currentType]);

    useEffect(() => {
        if (dataset) {
            setRevenueValues({
                fissoECPM: convertStringFloat(dataset.fissoECPM),
                percentuale: convertStringFloat(dataset.percentuale),
                fissoECPMParent: convertStringFloat(dataset.fissoECPMParent),
                percentualeParent: convertStringFloat(dataset.percentualeParent)
            })
        }
    }, [dataset])

    return (
        <>
            <Loading visible={isLoading} />
            <Navigation>
                <Header>
                    <Flex justify="space-between" align="center">
                        <div>
                            <div className="main-title">{id ? dataset?.nome : "Crea Inventory"}</div>
                        </div>
                        <div className="filter-section">
                            <BackButton />
                            <button onClick={handleSubmit} htmltype="submit" className="add-button">Salva</button>
                        </div>
                    </Flex>
                </Header>
                <div className="main">
                <Breadcrumb
                        separator=">"
                        items={[
                        {
                            href: "/",
                            title: (
                                <Flex gap="small" align="center">
                                    <IoHome />
                                    Home
                                </Flex>
                            ),
                        },
                        {
                            href: "/inventory",
                            title: 'Inventory',
                        },
                        {
                            title: id ? dataset?.nome : "Crea Inventory",
                            className: 'active-breadcrumb'
                        }
                        ]}
                    />
                    <Flex vertical gap="large">
                        <Collapse
                            expandIconPosition="end"
                            className="form-collapse"
                            items={[{ key: 'website', label: 'Website', children: <Website typeSelected={setTypeSelected} data={dataset} treeData={fullList} form={form}/> }]}
                            defaultActiveKey="website"
                            expandIcon={({ isActive }) => isActive ? <img alt="active-accordion" src="/custom-icons/CloseAccordion.svg" /> : <img alt="active-accordion" src="/custom-icons/OpenAccordion.svg" />}
                        />
                        {
                            (id || cardShowerByType || entityDetails?.fonti.length > 0) && 
                            <>
                                <span className="revenue-title">Revenue</span>
                                <Divider style={{  borderColor: 'black' }}/>
                            </>
                        }
                        <Collapse
                            className="form-collapse revenue-collapse"
                            collapsible="disabled"
                            items={[
                                {
                                    showArrow: false,
                                    key: 'default',
                                    label: <div style={{
                                        color: "#CE2472"
                                    }}>
                                        Default
                                    </div>,
                                    children: (() => {
                                        if (cardShowerByType === "dealership") {
                                            return (
                                                <Card className="inventory-card" title="Revenue Publisher">
                                                    <Revenue type={1} data={revenueValues} setRevenueValues={setRevenueValues} defaultCard={true} />
                                                </Card>
                                            );
                                        } else if (cardShowerByType === "publisher") {
                                            return (
                                                <Flex gap="small">
                                                    <Card className="inventory-card" title="Revenue Publisher">
                                                        <Revenue type={1} data={revenueValues} setRevenueValues={setRevenueValues} defaultCard={true}/>
                                                    </Card>
                                                    <Card className="inventory-card" title="Revenue Concessionaria">
                                                        <Revenue type={2} data={revenueValues} setRevenueValues={setRevenueValues} defaultCard={true}/>
                                                    </Card>
                                                </Flex>
                                            );
                                        }
                                        return null;
                                    })(),
                                    hidden: !(cardShowerByType === "dealership" || cardShowerByType === "publisher")
                                }
                                
                            ]}
                            defaultActiveKey="default"
                        />
                        {
                            id && entityDetails?.fonti.length > 0 &&
                            <SourceRevenue loggedUser={loggedUser} id={id} data={dataset} type={cardShowerByType} sourceList={sourceList} entityDetails={entityDetails} setRevenueValuesList={setRevenueValuesList}/>
                        }
                    </Flex>
                    
                </div>
            </Navigation>
        </>
    )
}

export default NewInventory;