import { Popconfirm } from "antd";
import { IoTrashOutline } from "react-icons/io5";

const DeleteConfirm = ({action, id}) => {
    return (
        <Popconfirm
            title="Elimina elemento"
            description="Sicuro di voler eliminare questo elemento?"
            okButtonProps={{style: {backgroundColor: "#CE2472"}}}
            onConfirm={() => action(id)}
            okText="Si"
            cancelText="No"
        >
            <IoTrashOutline size={20} />
        </Popconfirm>
    )
}

export default DeleteConfirm;