import { MdAccountCircle } from "react-icons/md";
import { IoExitSharp } from "react-icons/io5";

export const ProfileMenuItems = () => {
    const menuItems = [
        {
            key: "account",
            icon: <MdAccountCircle />,
            label: "Account",
            permission: true
        },
        {
            key: "logout",
            icon: <IoExitSharp />,
            label: "Logout",
            permission: true
        }
    ]

    return menuItems
                .filter(item => item.permission)
                .map(({ permission, ...rest }) => rest);
}