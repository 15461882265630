import { useState, useEffect } from "react";
import Navigation from "../../components/navigation/Navigation";
import Layout from "antd/es/layout/layout";
import { Popconfirm, Flex, Card, Form, Select, Input, Upload, Checkbox, Dropdown, Button, Drawer, Breadcrumb, Pagination } from "antd";
import { FaCaretDown } from "react-icons/fa";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { CiCirclePlus } from "react-icons/ci";  
import { IoIosCreate } from "react-icons/io";
import { FaPlusCircle } from "react-icons/fa";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { IoCloseCircle, IoHome, IoSearch } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from "../../components/navigation/BackButton";
import Loading from "../../components/loading/Loading";
import { UserManager } from '../../utils/tools/user-manager/UserManager';
import axios from 'axios';
import { notify } from "../../utils/tools/Tools";

const { Header } = Layout;
const { TextArea } = Input;
const { Dragger } = Upload;

dayjs.extend(relativeTime);

const apiUrl = window.AppConfig.API_ENDPOINT

const PlaylistDetails = ({mode}) => {
    const { id } = useParams();
    const [file, setFile] = useState(null);
    const [dataset, setDataset] = useState()
    const [selectedVideo, setSelectedVideo] = useState([]);
    const [checkAll, setCheckAll] = useState(false);
    const [open, setOpen] = useState(false);
    const [filtersDrawer, setFiltersDrawer] = useState({tag: [], category: [], text: ""});
    const [filtersApi, setFiltersApi] = useState({
        "Search":"",
        "CategorieId": [],
        "TagsId": [],
        "Page": 1,
    })
    const [filtersVideoPlaylist, setFiltersVideoPlaylist] = useState({
        "Search":"",
        "PlaylistId": parseInt(id),
        "Page": 1
    })
    const [pager, setPager] = useState()
    const [pagerDrawer, setPagerDrawer] = useState()
    const [currentPage, setCurrentPage] = useState(1)
    const [currentPageDrawer, setCurrentPageDrawer] = useState(1)
    const [videoListDrawer, setVideoListDrawer] = useState()
    const [videoList, setVideoList] = useState()
    const [tag, setTag] = useState([])
    const [category, setCategory] = useState([])
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoading, setIsLoading] = useState(true)
    const [loggedUser, setLoggedUser] = useState(null);
    const [trigger, setTrigger] = useState()

    const navigate = useNavigate()

    useEffect(() => {
        const user = UserManager();
        if (user) {
            setLoggedUser(user);
        }
    }, []);

    useEffect(() => {
        if (loggedUser) {
            if (mode === "edit") {
                axios.post(`${apiUrl}/api/v1/playlist/get-detail`, {id: parseInt(id)}, {
                    headers: {
                        'Authorization': `Bearer ${loggedUser.token.accessToken}`
                    }
                })
                    .then(response => {
                        setDataset(response.data.data);
                        setIsLoading(false);
                    })
                    .catch(error => {
                        console.error('Error:', error);
                        setIsLoading(false);
                    });
            }

            axios.post(`${apiUrl}/api/v1/tagcategorie/get-list-categorie`, {}, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
                .then(response => {
                    const categories = response.data.data.map((r) => ({label: r.nome, value: r.id}))
                    setCategory(categories)
                })
                .catch(error => {
                    console.error('Error:', error);
                });

            axios.post(`${apiUrl}/api/v1/tagcategorie/get-list-tags`, {}, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
                .then(response => {
                    const tags = response.data.data.map((r) => ({label: r.nome, value: r.id}))
                    setTag(tags)
                })
                .catch(error => {
                    console.log('Error:', error);
                });
        }

        setIsLoading(false)
    }, [loggedUser, trigger])

    useEffect(() => {
        if (loggedUser) {
            axios.post(`${apiUrl}/api/v1/playlist/get-video`, filtersVideoPlaylist, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
                .then(response => {
                    setVideoList(response.data.data)
                    setPager(response.data.pager)
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }, [loggedUser, trigger, filtersVideoPlaylist])

    useEffect(() => {
        if (loggedUser) {
            axios.post(`${apiUrl}/api/v1/video/get-list`, filtersApi, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
                .then(response => {
                    setVideoListDrawer(response.data.data);
                    setPagerDrawer(response.data.pager)
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }, [loggedUser, filtersApi])

    const addVideoToPlaylist = () => {
        setIsLoading(true);
        axios.put(`${apiUrl}/api/v1/playlist/add-video`, {id: parseInt(id), videoId: selectedVideo}, {
            headers: {
                'Authorization': `Bearer ${loggedUser.token.accessToken}`
            }
        })
            .then(response => {
                setSelectedVideo([])
                setTrigger(new Date())
                setOpen(false)
                setIsLoading(false);
                notify("Video aggiunto correttamente alla Playlist")
            })
            .catch(error => {
                notify("Video già presente, selezionane altri!")
                console.error('Error:', error);
                setIsLoading(false);
            });
    }

    const removeVideoFromPlaylist = () => {
        axios.put(`${apiUrl}/api/v1/playlist/remove-video`, {id: parseInt(id), videoId: selectedVideo}, {
            headers: {
                'Authorization': `Bearer ${loggedUser.token.accessToken}`
            }
        })
            .then(response => {
                setSelectedVideo([])
                setTrigger(new Date())
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    const handleChange = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchQuery(value);
    };

    useEffect(() => {
        if (searchQuery.length > 2) {
            setFiltersVideoPlaylist(prevState => ({
                ...prevState, 
                Search: searchQuery
            }));
        }

        if (searchQuery.length === 0) {
            setFiltersVideoPlaylist(prevState => ({
                ...prevState, 
                Search: ""
            }));
        } 
    }, [searchQuery])

    const deleteEntity = () => {
        if (loggedUser) {
            axios.delete(`${apiUrl}/api/v1/playlist/delete`, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                },
                data: { id: parseInt(id) } 
            })
            .then(response => {
                navigate("/playlist");
            })
            .catch(error => {
                console.error('Error:', error);
            });
        }
    }

    const itemsVideo = [
        {
            key: '1',
            label: <Popconfirm
                        title="Elimina elemento"
                        description="Sicuro di voler eliminare questo elemento?"
                        okButtonProps={{style: {backgroundColor: "#CE2472"}}}
                        onConfirm={() => removeVideoFromPlaylist()}
                        okText="Si"
                        cancelText="No"
                    >
                        Rimuovi
                    </Popconfirm>
        },
        {
            key: '2',
            label: 'Annulla',
            onClick: () => setSelectedVideo([])
        },
    ];

    const itemsVideoDrawer = [
        {
            key: '1',
            label: 'Aggiungi',   
            onClick: () => addVideoToPlaylist()        
        },
        {
            key: '2',
            label: 'Annulla',
            onClick: () => setSelectedVideo([])
        },
    ];

    const handleCheckboxChange = (event, id) => {
        if (event.target.checked) {
            setSelectedVideo([...selectedVideo, id]);
        } else {
            setSelectedVideo(selectedVideo.filter(item => item !== id));
        }
    };

    const handleCheckAllChange = (event) => {
        const { checked } = event.target;
        setCheckAll(checked);
        if (checked) {
            const allIds = [].map(video => video.id);
            setSelectedVideo(allIds);
        } else {
            setSelectedVideo([]);
        }
    };

    const handleCreatePlaylist = (values) => {
        if (loggedUser) {
            if (mode === "new") {
                const formData = new FormData();
                formData.append('file', file);

                axios.post(`${apiUrl}/api/v1/playlist/upload/0`, formData, {
                    headers: {
                        'Authorization': `Bearer ${loggedUser.token.accessToken}`,
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then(response => {
                    const data = {
                        "id": response.data.data.id,
                        "titolo": values.titolo,
                        "descrizione": values.descrizione,
                        "categoriaId": 0,
                        "tags": values.tags,
                        "utentiId": loggedUser.user.id,
                        "status": "attivo"
                    }                
                    axios.put(`${apiUrl}/api/v1/playlist/upsert`, data, {
                        headers: {
                            'Authorization': `Bearer ${loggedUser.token.accessToken}`,
                        },
                    })
                    .then(response => {
                        navigate(`/playlist/${data.id}`)
                        window.location.reload()
                    })
                    .catch(error => {
                        console.error('Error:', error);
                        setIsLoading(false)
                    });
                })
                .catch(error => {
                    console.error('Error:', error);
                    setIsLoading(false)
                });
                setFile(null);
            } else {
                if (file) {
                    const formData = new FormData();
                    formData.append('file', file);

                    axios.post(`${apiUrl}/api/v1/playlist/upload/${id}`, formData, {
                        headers: {
                            'Authorization': `Bearer ${loggedUser.token.accessToken}`,
                            'Content-Type': 'multipart/form-data',
                        },
                    })
                    .then(response => {
                        notify("Playlist salvata con successo")
                        setTrigger(new Date())
                    })
                }

                const data = {
                    "id": parseInt(id),
                    "titolo": values.titolo,
                    "descrizione": values.descrizione,
                    "copertina": dataset.copertina,
                    "categoriaId": 0,
                    "tags": values.tags,
                    "utentiId": loggedUser.user.id,
                    "status": "attivo"
                }  
                
                axios.put(`${apiUrl}/api/v1/playlist/upsert`, data, {
                    headers: {
                        'Authorization': `Bearer ${loggedUser.token.accessToken}`,
                    },
                })
                .then(response => {
                    notify("Playlist salvata con successo")
                })
            }
        }
    }

    const applyDrawerFilters = () => {
        setFiltersApi(prevState => ({
            ...prevState, 
                Search:filtersDrawer.text,
                CategorieId: filtersDrawer.category,
                TagsId: filtersDrawer.tag,
            }))
    }

    const setVideoFilters = (type, values) => {
        switch (type) {
            case "text": setFiltersDrawer(prevState => ({
                ...prevState, text: values.target.value}))
                break;
            case "tag": setFiltersDrawer(prevState => ({
                ...prevState, tag: values}))
                break;
            case "category": setFiltersDrawer(prevState => ({
                ...prevState, category: values}))
                break;
            case "apply": applyDrawerFilters()
        }
    };

    const pageChange = (event) => {
        setCurrentPage(event)
        setFiltersApi(prevState => ({
            ...prevState, 
            Page: event
        }));
    }

    const pageChangeDrawer = (event) => {
        setCurrentPageDrawer(event)
        setFiltersDrawer(prevState => ({
            ...prevState, 
            Page: event
        }));
    }

    return (
        <>
            <Loading visible={isLoading} />
            <div className="video-drawer">
                <Drawer style={{borderRadius: "65px 65px 0 0"}} height="90%" className="video-drawer" placement="bottom" onClose={() => setOpen(false)} open={open} closeIcon={<img alt="close-drawer" className="close-drawer-video" src="/custom-icons/CloseDrawer.svg" />}>
                    <Flex justify="center"><h1>Aggiungi Video</h1></Flex>
                    <div className="video-selector-drawer">
                        <Form
                            layout="inline"
                            name="anagrafica"
                            style={{width: "100%"}}
                            initialValues={{
                                remember: true,
                            }}
                            validateMessages={{required: false}}
                            onFinish={handleCreatePlaylist}
                        >
                            <Flex style={{width: "100%"}} align="flex-start" gap={40} wrap>
                                <Form.Item
                                    name="titolo"
                                    style={{width: "30%"}}
                                >
                                    <Input onChange={(val) => setVideoFilters("text", val)} placeholder="Ricerca" prefix={<IoSearch />}  />
                                </Form.Item>
                                <Form.Item
                                    name="category"
                                    style={{width: "25%"}}
                                >
                                    <Select
                                        optionFilterProp="label"
                                        mode="multiple"
                                        allowClear
                                        placeholder="Select category"
                                        options={category}
                                        onChange={(val) => setVideoFilters("category", val)}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="tags"
                                    style={{width: "25%"}}
                                >
                                    <Select
                                        optionFilterProp="label"
                                        mode="multiple"
                                        allowClear
                                        placeholder="Select tags"
                                        options={tag}
                                        onChange={(val) => setVideoFilters("tag", val)}
                                    />
                                </Form.Item>
                                <button style={{width: "10%"}} onClick={() => setVideoFilters("apply", null)} className="add-button">Cerca</button>
                            </Flex>
                        </Form>
                        {
                            selectedVideo.length > 0 &&
                            <div className="playlist-action action-drawer">
                                <Flex gap="large">
                                    <Checkbox
                                        onChange={handleCheckAllChange}
                                        checked={checkAll}
                                        className="check-all-checkbox"
                                    >
                                        <div className="select-all">{checkAll ? "Deseleziona tutto" : "Seleziona tutto"}</div>
                                    </Checkbox>
                                    <Dropdown menu={{items: itemsVideoDrawer}}>
                                        <div>
                                            Azioni <FaCaretDown />
                                        </div>
                                    </Dropdown>
                                </Flex>
                                <Button onClick={() => {setCheckAll(false); setSelectedVideo([])}} className="close-icon">
                                    <IoIosCloseCircleOutline size={20} />
                                </Button>
                            </div>
                        }
                        <div className="card-container">
                            <div className="custom-row">
                                {videoListDrawer?.map((item) => (
                                    <Card
                                        key={`video-select-${item.id}`}
                                        className="custom-col video-card video-selector"
                                        cover={
                                            <div className="image-container">
                                                <video
                                                    className="video-content"
                                                    controls={true}
                                                >
                                                    <source src={item.fileUrl} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                                <div className="overlay-checkbox">
                                                    <Checkbox onChange={(event) => handleCheckboxChange(event, item.id)}
                                                        className="custom-checkbox" checked={selectedVideo.includes(item.id)}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    >
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <b>{item.titolo}</b>
                                        </div>
                                        <div className="tag-section">
                                            {item.tags.map(element => tag.find(t => t.value === element)?.label)}
                                        </div>
                                    </Card>
                                ))}
                            </div>
                        </div>
                    </div>
                    <Pagination style={{paddingTop: 30}} defaultPageSize={20} current={currentPageDrawer} onChange={pageChangeDrawer} total={pagerDrawer?.totalItems} />
                </Drawer>
            </div>
            
            <Navigation>
                <Header>
                    <Flex justify="space-between" align="center">
                        <div>
                            <div className="main-title">
                                {mode === "new" ? "Creazione Playlist" : "Dettaglio Playlist"}
                            </div>
                        </div>
                        <div className="filter-section">
                            <BackButton />
                            {
                                mode === "edit" &&
                                <Popconfirm
                                title="Elimina elemento"
                                description="Sicuro di voler eliminare questo elemento?"
                                okButtonProps={{style: {backgroundColor: "#CE2472"}}}
                                onConfirm={() => deleteEntity()}
                                okText="Si"
                                cancelText="No"
                                >
                                    <button className="print-button">
                                        Elimina
                                        <IoCloseCircle />
                                    </button>
                                </Popconfirm>
                            }
                        </div>
                    </Flex>
                </Header>
                <div className="main">
                    <Breadcrumb
                        separator=">"
                        items={[
                        {
                            href: "/",
                            title: (
                                <Flex gap="small" align="center">
                                    <IoHome />
                                    Home
                                </Flex>
                            ),
                        },
                        {
                            href: "/playlist",
                            title: 'Playlist',
                        },
                        {
                            title: mode === "new" ? "Creazione Playlist" : "Dettaglio",
                            className: 'active-breadcrumb'
                        },
                        ]}
                    />
                    <Flex gap="small">
                        {
                            mode === "new" ? 
                            <Dragger name='file'
                                    accept='image/*'
                                    beforeUpload={(file) => {
                                        setFile(file);
                                        return false;
                                    }}
                                    onRemove={() => setFile(null)} 
                                    className="drag-drop file-video-card">
                                <CiCirclePlus size="5em" />
                                <p>Aggiungi un'immagine di copertina</p>
                            </Dragger> :
                            <Card className="file-video-card" style={
                                {
                                    background: `url(${dataset?.copertina})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                }
                            }>
                                <div className="photo-editor">
                                    <Upload name='file'
                                            accept='image/*'
                                            beforeUpload={(file) => {
                                                setFile(file);
                                                return false;
                                            }}
                                            onRemove={() => setFile(null)} 
                                            className="drag-drop file-video"
                                    >
                                        <IoIosCreate size={30} /> 
                                    </Upload>   
                                </div>
                            </Card>
                        }
                        <Card className="form-card file-video-edit-card" title="Dati Playlist">
                            <Form
                                layout="inline"
                                name="anagrafica"
                                initialValues={{
                                    remember: true,
                                }}
                                validateMessages={{required: false}}
                                onFinish={handleCreatePlaylist}
                                fields={[
                                    {
                                        name: ["titolo"],
                                        value: dataset?.titolo
                                    },
                                    {
                                        name: ["tags"],
                                        value: dataset?.tags
                                    },
                                    {
                                        name: ["descrizione"],
                                        value: dataset?.descrizione
                                    }
                                ]}
                            >
                                <Flex vertical gap="large" style={{width: "100%"}}>
                                    <Flex>
                                        <Form.Item
                                            name="titolo"
                                            style={{width: "100%"}}
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Nome" />
                                        </Form.Item>

                                    </Flex>
                                        <Form.Item
                                            name="tags"
                                            style={{width: "100%"}}
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Select
                                                optionFilterProp="label"
                                                mode="multiple"
                                                allowClear
                                                style={{
                                                    width: '100%',
                                                }}
                                                placeholder="Select tags"
                                                options={tag}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="descrizione"
                                            style={{width: "100%"}}
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <TextArea rows={3} placeholder="Descrizione" />
                                        </Form.Item>                                 
                                    <Flex justify="right" align="center">
                                        <button htmltype="submit" className="save-button">Salva</button>
                                    </Flex>
                                </Flex>   
                            </Form>
                        </Card>
                    </Flex>
                    {
                        mode === "edit" &&
                        <Flex vertical>
                        {
                            selectedVideo.length > 0 ?
                            <Flex vertical gap="large">
                                <div className="playlist-action">
                                    <Flex gap="large">
                                        <Checkbox
                                            onChange={handleCheckAllChange}
                                            checked={checkAll}
                                            className="check-all-checkbox"
                                        >
                                            <div className="select-all">{checkAll ? "Deseleziona tutto" : "Seleziona tutto"}</div>
                                        </Checkbox>
                                        <Dropdown menu={{items: itemsVideo}}>
                                            <div>
                                                Azioni <FaCaretDown />
                                            </div>
                                        </Dropdown>
                                    </Flex>
                                    <Button onClick={() => {setCheckAll(false); setSelectedVideo([])}} className="close-icon">
                                        <IoIosCloseCircleOutline size={20} />
                                    </Button>
                                </div>
                            </Flex>
                            : 
                            <Flex justify="space-between" align="center" className="playlist-title">
                                <div>Video in questa Playlist</div>
                                <button onClick={() => {setOpen(true); setCheckAll(false); setSelectedVideo([])}} className="add-button">
                                    Aggiungi video
                                    <FaPlusCircle />
                                </button>
                            </Flex>
                        }
                        <Input value={searchQuery} onChange={handleChange} className="table-search playlist-search" size="large" placeholder="Ricerca" prefix={<IoSearch />} />
                        <div className="playlist-container">
                            {
                                !open &&
                                <table className="playlist-section" style={{ width: '100%', borderCollapse: 'collapse' }}>
                                    <tbody>
                                        {videoList?.map((video) => (
                                            <tr key={video.id} style={{ display: 'flex', alignItems: 'center', gap: 'large', borderBottom: '1px solid #eee', padding: '10px 0' }}>
                                                <td width={"45%"}>
                                                    <Flex gap="large" align="center">
                                                        <div className="video-content-playlist-container">
                                                            <video
                                                                className="video-content-playlist"
                                                                controls={true}
                                                            >
                                                                <source src={video.fileUrl} type="video/mp4" />
                                                                Your browser does not support the video tag.
                                                            </video>
                                                            <Checkbox onChange={(event) => handleCheckboxChange(event, video.id)}
                                                                className="custom-checkbox-adjusted custom-checkbox" checked={selectedVideo.includes(video.id)}
                                                            />
                                                        </div>
                                                        <Flex vertical align="left">
                                                            <div className="title">{video.titolo}</div>
                                                            <div className="description">{video.descrizione}</div>
                                                        </Flex>
                                                    </Flex>
                                                </td>
                                                <td width={"33%"}>
                                                    {
                                                        category.find(c => c.value === video.categoriaId)?.label ? 
                                                            <div className="category-box playlist-category">{category.find(c => c.value === video.categoriaId)?.label}</div> :
                                                            <></>
                                                    }
                                                </td>
                                                <td  width={"33%"}>
                                                    <div className="tag-section playlist-tag">
                                                        {video.tags.map(item => tag.find(t => t.value === item)?.label)}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            }
                        </div>

                        <Pagination style={{paddingTop: 30}} defaultPageSize={20} current={currentPage} onChange={pageChange} total={pager?.totalItems} />
                        
                        </Flex>
                    }
                    
                </div>
            </Navigation>
        </>
    )
}

export default PlaylistDetails;