import React, {useEffect, useState} from 'react';
import './style.css'
import { GeneralMenuItems } from '../../utils/menu/GeneralMenuItems';
import { Layout, Menu } from 'antd';
import { VideoMenuItems } from '../../utils/menu/VideoMenuItems';
import { ProfileMenuItems } from '../../utils/menu/ProfileMenuItems';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md";
import { Toaster } from 'react-hot-toast';


const { Sider } = Layout;

const extractBasePath = (pathname) => {
  const pathSegments = pathname.split('/').filter(segment => segment);
  return pathSegments.length > 0 ? pathSegments[0] === "dashboard" ? "" : pathSegments[0] : '';
};

const Navigation = ({collapsedStatus = false, children}) => {
  const [collapsed, setCollapsed] = useState(collapsedStatus);
  const location = useLocation();
  const navigate = useNavigate();
  const basePath = extractBasePath(location.pathname);

  useEffect(() => {
    if (window.innerWidth <= 800) {
      setCollapsed(true);
    }
  }, [])

  return (
    <Layout className='main-layout'>
      <Toaster position="top-right" toastOptions={{
          duration: 5000,
          style: {
            background: '#CE2472',
            color: 'white',
          }
        }}
      />
      <Sider
        trigger={null}
        breakpoint="lg"
        collapsible
        collapsed={collapsed}
        width={225}
        style={{minWidth: 60}}
      >
        <div className="logo-container">
          <img src={collapsed ? "/stretched-logo.svg" : "/logo.svg"} alt='mediately' className="logo" />
          <div>
            {
              collapsed ? 
                <MdKeyboardDoubleArrowRight className="trigger-collapse-button" onClick={() => setCollapsed(false)}/> :
                <MdKeyboardDoubleArrowLeft className="trigger-collapse-button" onClick={() => setCollapsed(true)}/>
            }
          </div>
        </div>
        <hr className="divider" />
        <div className="menu-title">General</div>
        <Menu defaultSelectedKeys={[basePath]} onClick={(path) => navigate(`/${path.key}`)} mode="inline" items={GeneralMenuItems()} />
        <div className="menu-title">Video</div>
        <Menu defaultSelectedKeys={[basePath]} onClick={(path) => navigate(`/${path.key}`)} mode="inline" items={VideoMenuItems()} />
        <div className="menu-title">Profile</div>
        <Menu defaultSelectedKeys={[basePath]} onClick={(path) => navigate(`/${path.key}`)} mode="inline" items={ProfileMenuItems()} />
      </Sider>
      <Layout className='main-layout'>
        {children}
      </Layout>
    </Layout>
  );
};

export default Navigation;