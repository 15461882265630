import { useEffect, useState } from "react";
import { Table, Input } from "antd";
import { IoSearch } from "react-icons/io5";
import Statistic from "antd/es/statistic/Statistic";
import dayjs from "dayjs";

const DashboardTable = ({data, setFiltersApi, pageInfo, user, permissions}) => {
    const [tableData, setTableData] = useState(data)
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        setTableData(data)
    }, [data])

    const handleChange = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchQuery(value);
    };

    useEffect(() => {
        if (searchQuery.length > 2) {
            setFiltersApi(prevState => ({
                ...prevState, 
                Search: searchQuery
            }));
        }

        if (searchQuery.length === 0) {
            setFiltersApi(prevState => ({
                ...prevState, 
                Search: ""
            }));
        } 
    }, [searchQuery])

    const columns = [
        {
            title: 'Date',
            dataIndex: 'DtCreazione',
            width: 150,
            render: (row, elem) => dayjs(elem.dtReport).format("DD/MM/YYYY"),
        },
        {
            title: 'Company',
            dataIndex: 'company',
            width: 150,
            render: (row, elem) => <a className="name-col" href={`/dashboard/${elem.publisherId}?type=publisher`}>{elem.publisherName}</a>
        },
        {
            title: 'Site',
            dataIndex: 'sito',
            width: 200,
            render: (row, elem) => <a className="name-col" href={`/dashboard/${elem.sito}?type=sito`}>{elem.sito}</a>
        },
        {
            title: 'Month',
            dataIndex: 'mese',
            width: 100
        },
        {
            title: 'Paid Impr.',
            dataIndex: 'paidImpressions',
            width: 100,
            render: (row, elem) => <Statistic className="table-statistic" groupSeparator="." value={elem.paidImpressions} decimalSeparator="," precision={0}/>
        },
        {
            title: 'Gross Rev.',
            dataIndex: 'estGrosRevenue',
            width: 100,
            render: (row, elem) => {
                let value
                switch (user?.ruolo) {
                    case "Admin": value = elem.estGrosRevenue
                        break;
                    case "Concessionaria": value = elem.estConcRevenue
                        break;
                    case "Publisher": value = elem.estPublRevenue
                        break;
                }

                return <Statistic className="table-statistic" groupSeparator="." value={value} decimalSeparator="," precision={2} suffix="€"/>
            }
        },
        {
            title: 'Pub Rev.',
            dataIndex: 'estPublRevenue',
            width: 100,
            render: (row, elem) => {
                let value
                switch (user?.ruolo) {
                    case "Admin": value = elem.estConcRevenue
                        break;
                    case "Concessionaria": value = elem.estPublRevenue
                        break;
                    case "Publisher": value = 0
                        break;
                }

                return <Statistic className="table-statistic" groupSeparator="." value={value} decimalSeparator="," precision={2} suffix="€"/>
            },
            hidden: !permissions?.canSeePubRevColumn
        },
        {
            title: 'AdRequest',
            dataIndex: 'impressions',
            width: 110,
            render: (row, elem) => <Statistic className="table-statistic" groupSeparator="." value={elem.impressions} decimalSeparator="," precision={0}/>
        },
        {
            title: 'Fill Rate',
            dataIndex: 'fillRate',
            width: 100,
            render: (row, elem) => <Statistic className="table-statistic" groupSeparator="." value={elem.fillRate} decimalSeparator="," precision={2} suffix="%"/>
        },
        {
            title: 'Clicks',
            dataIndex: 'clicks',
            width: 80,
            render: (row, elem) => <Statistic className="table-statistic" groupSeparator="." value={elem.clicks} decimalSeparator="," precision={0}/>
        },
        {
            title: 'eCPM',
            dataIndex: 'ecpm',
            width: 80,
            render: (row, elem) => <Statistic className="table-statistic" groupSeparator="." value={elem.ecpmConc} decimalSeparator="," precision={2} suffix="€"/>
        },
        {
            title: 'Viewability',
            dataIndex: 'viewability',
            width: 110,
            render: (row, elem) => <Statistic className="table-statistic" groupSeparator="." value={elem.viewability} decimalSeparator="," precision={2} suffix="%"/>
        },
        {
            title: 'Fonte',
            dataIndex: 'fonte',
            width: 110,
            hidden: !permissions?.canSeeFonte
        },
    ];

    const rowClassName = (record, index) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };

    const totalElements = pageInfo?.totalItems;
    
    return (
        <>
            <Input value={searchQuery} onChange={handleChange} className="table-search" size="large" placeholder="Ricerca" prefix={<IoSearch />} />
            <Table
                rowKey="id"
                columns={columns}
                dataSource={tableData}
                pagination={{
                    pageSize: pageInfo?.pageSize,
                    total: totalElements,
                }}
                scroll={{y: "100%", x: 1300}}
                rowClassName={rowClassName}
                pageSize={pageInfo?.pager?.totalPages}
                onChange={(data) => {
                    setFiltersApi(prevState => ({
                        ...prevState, 
                        Page: data.current,
                        Size: data.pageSize
                    }));
                }}
            />
        </>
    )
}

export default DashboardTable;