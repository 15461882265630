import Navigation from "../../components/navigation/Navigation";
import Layout from "antd/es/layout/layout";
import { Card, Drawer, Flex } from 'antd';
import "./style.css"
import { useEffect, useState } from "react";
import BarChart from "../../components/chart/BarChart";
import PieChart from "../../components/chart/PieChart";
import DashboardTable from "../../components/table/DashboardTable";
import { DatePicker, Collapse, Select, Row, Col } from 'antd';
import { IoCalendarClear, IoCaretDown, IoFilter, IoCloseCircleOutline } from "react-icons/io5";
import { useParams, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import Statistic from "antd/es/statistic/Statistic";
import { cardTitleDecoder } from "../../utils/tools/Tools";
import Loading from "../../components/loading/Loading";
import axios from "axios";
import BackButton from "../../components/navigation/BackButton";
import { UserManager } from "../../utils/tools/user-manager/UserManager";
import { permissions } from "../../utils/tools/roles/roles";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const { RangePicker } = DatePicker;
const { Header } = Layout;

const apiUrl = window.AppConfig.API_ENDPOINT
const cardOrder = window.AppConfig.CARD_ORDER

const isSameOrBefore = require('dayjs/plugin/isSameOrBefore');
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const startDate = dayjs().subtract(30, 'day');
const endDate = dayjs();

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 6
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const Dashboard = () => {
    const { id } = useParams()
    const location = useLocation()
    const query = new URLSearchParams(location.search);
    const type = query.get('type');
    const [permissionsRole, setPermissionsRole] = useState()
    const [dataset, setDataset] = useState()
    const [date, setDate] = useState([dayjs().subtract(30, 'day'), dayjs()])
    const [filtersApi, setFiltersApi] = useState({
        "Search":"",
        "FonteId": [],
        "DtFrom": startDate.format('DD/MM/YYYY'),
        "DtTo": endDate.format('DD/MM/YYYY'),
        "ConcessionariaId": [],
        "PublisherId": type === "publisher" ? [parseInt(id)] : [],
        "Site": type === "sito" ? [id] : [],
        "Page": 1,
    })      
    const [pager, setPager] = useState()
    const [open, setOpen] = useState(false);
    const [selectedCard, setSelectedCard] = useState("EcpmGross")
    const [chartsData, setChartsData] = useState([])
    const [dealershipList, setDealershipList] = useState([]);
    const [fullDealershipList, setFullDealershipList] = useState([]);
    const [fullPublisherList, setFullPublisherList] = useState([]); 
    const [publisherList, setPublisherList] = useState([]); 
    const [fullWebsiteList, setFullWebsiteList] = useState([]);
    const [websiteList, setWebsiteList] = useState([]); 
    const [sourceList, setSourceList] = useState([])
    const [sidebarFilters, setSidebarFilters] = useState({dealership: [], publisher: [], website: [], fonti: []})
    const [isLoading, setIsLoading] = useState(true)
    const [loggedUser, setLoggedUser] = useState(null);
    const [cardData, setCardData] = useState({ ecpmGross: 0, adRequest: 0, paidImpression: 0, fillRate: 0, ecpmEditore: 0, revenueGross: 0, revenueEditore: 0 })

    useEffect(() => {
        const user = UserManager();
        if (user) {
            setLoggedUser(user);
            setPermissionsRole(permissions[user.user.ruolo]);
        }
    }, []);

    useEffect(() => {
        if (loggedUser) {
            axios.post(`${apiUrl}/api/v1/reports/get-list`, filtersApi, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
                .then(response => {
                    setDataset(response.data.data)
                    setPager(response.data.pager)
                    setIsLoading(false)
                })
                .catch(error => {
                    console.error('Error:', error);
                    setIsLoading(false)
                });
        }
    }, [filtersApi, loggedUser])

    useEffect(() => {
        if (loggedUser) {
            axios.post(`${apiUrl}/api/v1/reports/get-chart`, {...filtersApi, Type: selectedCard}, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
                .then(response => {
                    setChartsData(response.data.data)
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }, [filtersApi, selectedCard, loggedUser])

    useEffect(() => {
        if (loggedUser) {
            axios.post(`${apiUrl}/api/v1/init/list-concessionarie`, {}, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
                .then(response => {
                    const data = response.data
                    const list = data.map(element => ({label: element.text, value: parseInt(element.value)}))
                    setDealershipList(list)
                    setFullDealershipList(list)
                })
                .catch(error => {
                    console.error('Error:', error);
                });
    
            axios.post(`${apiUrl}/api/v1/init/list-fonti`, {}, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
            .then(response => {
                const data = response.data.map(item => ({value: parseInt(item.value), label: item.text}))
                setSourceList(data)
            })
            .catch(error => {
                console.error('Error:', error);
            });

            axios.post(`${apiUrl}/api/v1/init/list-publishers`, {}, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
                .then(response => {
                    const data = response.data
                    const list = data.map(element => ({label: element.text, value: parseInt(element.value), text1: parseInt(element.text1)}))
                    setPublisherList(list)
                    setFullPublisherList(list)
                })
                .catch(error => {
                    console.error('Error:', error);
                });
    
            axios.post(`${apiUrl}/api/v1/init/list-inventory`, {}, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
                .then(response => {
                    const data = response.data
                    const list = data.map(element => ({label: element.text, value: element.text, text1: parseInt(element.text1), id: parseInt(element.value)}))
                    setWebsiteList(list)
                    setFullWebsiteList(list)
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }, [loggedUser])

    const setFiltersSidebar = (type, values) => {
        switch (type) {
            case "dealership": setSidebarFilters(prevState => ({
                ...prevState, dealership: values}))
                break;
            case "publisher": setSidebarFilters(prevState => ({
                ...prevState, publisher: values}))
                break;
            case "website": setSidebarFilters(prevState => ({
                ...prevState, website: values}))
                break;
            case "fonti": setSidebarFilters(prevState => ({
                ...prevState, fonti: values}))
                break;
            case "apply": handleFilter()
                break;
        }
    };

    const handleFilter = () => {
        setIsLoading(true)
        setOpen(false)
        setFiltersApi(prevState => ({
            ...prevState, 
            ConcessionariaId: sidebarFilters.dealership, 
            PublisherId: sidebarFilters.publisher,
            Site: sidebarFilters.website,
            FonteId: sidebarFilters.fonti
        }));
    }

    const handleRangePresets = (value) => {
        switch (value) {
            case "year": setDate([dayjs().subtract(1, "year"), dayjs()])
                break;
            case "month": setDate([dayjs().subtract(1, "month"), dayjs()])
                break;
            case "week": setDate([dayjs().subtract(1, "week"), dayjs()])
                break;
            case "day": setDate([dayjs().subtract(1, "day"), dayjs()])
                break;
        }
    }

    useEffect(() => {
        setIsLoading(true)
        setFiltersApi(prevState => ({
            ...prevState, 
            DtFrom: date[0].format('DD/MM/YYYY'),
            DtTo: date[1].format('DD/MM/YYYY')
        }));
    }, [date])

    const handleRangeChange = (value) => {
        setDate(value);
        setFiltersApi(prevState => ({
            ...prevState, 
            DtFrom: value[0].format("DD/MM/YYYY"), 
            DtTo: value[1].format("DD/MM/YYYY")
        }));
    }

    useEffect(() => {
        const { publisher, dealership, website } = sidebarFilters;
    
        // Inizializzazione delle liste da filtrare
        let filteredPublisherList = fullPublisherList;
        let filteredWebsiteList = fullWebsiteList;
        let filteredDealershipList = fullDealershipList;
    
        // Logica a cascata per dealership
        if (dealership.length > 0) {
            filteredPublisherList = fullPublisherList.filter(item => dealership.includes(item.text1));
            filteredWebsiteList = fullWebsiteList.filter(item => dealership.includes(item.text1));
        } else {
            filteredPublisherList = fullPublisherList;
            filteredWebsiteList = fullWebsiteList;
        }
    
        // Logica a cascata per publisher
        if (publisher.length > 0) {
            filteredWebsiteList = fullWebsiteList.filter(item => publisher.includes(item.text1));
            //filteredDealershipList = fullDealershipList.filter(item => publisher.includes(item.text1));
        } else if (dealership.length === 0) {
            filteredDealershipList = fullDealershipList;
        }
    
        // Logica a cascata per website
        //if (website.length > 0) {
            //const websiteText1List = fullWebsiteList.filter(item => website.includes(item.value)).map(item => item.text1);
            //filteredPublisherList = fullPublisherList.filter(item => websiteText1List.includes(item.text1));
            //filteredDealershipList = fullDealershipList.filter(item => websiteText1List.includes(item.value));
        //}
    
        // Set delle liste filtrate
        setPublisherList(filteredPublisherList);
        setWebsiteList(filteredWebsiteList);
        setDealershipList(filteredDealershipList);
    
    }, [sidebarFilters.publisher, sidebarFilters.dealership, sidebarFilters.website, fullPublisherList, fullWebsiteList, fullDealershipList]);
    
    useEffect(() => {
        if ((sidebarFilters.publisher.length > 0 || sidebarFilters.website.length > 0) && sidebarFilters.dealership.length > 0) {
            setFiltersSidebar("publisher", []);
            setFiltersSidebar("website", []);
            
        }
    }, [sidebarFilters.dealership])

    useEffect(() => {
        if (sidebarFilters.website.length > 0 && sidebarFilters.publisher.length > 0) {
            setFiltersSidebar("website", []);
        }
    }, [sidebarFilters.publisher])

    const items = [
        {
            key: '1',
            label: 'Concessionarie',
            children: <Select
                optionFilterProp="label"
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Select concessionarie"
                onChange={(values) => setFiltersSidebar("dealership", values)}
                options={dealershipList}
                value={sidebarFilters.dealership}
            />,
        },
        {
            key: '2',
            label: 'Publisher',
            children: <Select
                optionFilterProp="label"
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Select publisher"
                onChange={(values) => setFiltersSidebar("publisher", values)}
                options={publisherList}
                value={sidebarFilters.publisher}
            />,
        },
        {
            key: '3',
            label: 'Sito',
            children: <Select
                optionFilterProp="label"
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Select sites"
                onChange={(values) => setFiltersSidebar("website", values)}
                options={websiteList}
                value={sidebarFilters.website}
            />,
        },
        {
            key: '4',
            label: 'Fonti',
            children: <Select
                optionFilterProp="label"
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Seleziona fonti"
                onChange={(values) => setFiltersSidebar("fonti", values)}
                options={sourceList}
                value={sidebarFilters.fonti}
            />,
            hidden: !permissionsRole?.canSeeFonte
        },
    ];
    
    const cardStyle = (cardName, bgColor, borderColor) => ({
        border: selectedCard === cardName ? `2px solid ${borderColor}` : 0,
        background: bgColor,
    });

    useEffect(() => {
        if (loggedUser && dataset) { 
            switch (loggedUser.user.ruolo) {
                case "Admin": setCardData({
                    ecpmGross: dataset.totals.ecpmGross,
                    adRequest: dataset.totals.impressions,
                    paidImpression: dataset.totals.paidImpressions,
                    fillRate: dataset.totals.fillRate,
                    ecpmEditore: dataset.totals.ecpmConc,
                    revenueGross: dataset.totals.revenueGross,
                    revenueEditore: dataset.totals.revenueConc
                })
                    break;
                case "Concessionaria": setCardData({
                    ecpmGross: dataset.totals.ecpmConc,
                    adRequest: dataset.totals.impressions,
                    paidImpression: dataset.totals.paidImpressions,
                    fillRate: dataset.totals.fillRate,
                    ecpmEditore: dataset?.totals.ecpmPubl,
                    revenueGross: dataset.totals.revenueConc,
                    revenueEditore: dataset.totals.revenuePubl
                })
                    break;
                case "Publisher": setCardData({
                    ecpmGross: dataset.totals.ecpmPubl,
                    adRequest: dataset.totals.impressions,
                    paidImpression: dataset.totals.paidImpressions,
                    fillRate: dataset.totals.fillRate,
                    revenueGross: dataset.totals.revenuePubl,
                    ecpmEditore: null,
                    revenueEditore: null,
                })
                    break;
            } 
        }
    }, [dataset, loggedUser])

    const cards = [
        {
            key: "EcpmGross",
            permission: true, // Assuming this card is always visible
            component: (
                <Card className="dashboard-card" style={cardStyle("EcpmGross", "#F9EBDD", "#DFB182")} onClick={() => setSelectedCard("EcpmGross")}>
                    <span className="dashboard-category">ECPM Gross</span>
                    <Statistic className="dashboard-value" groupSeparator="." value={cardData.ecpmGross} precision={2} suffix="€" />
                </Card>
            )
        },
        {
            key: "RevenueGross",
            permission: true, // Assuming this card is always visible
            component: (
                <Card className="dashboard-card" style={cardStyle("RevenueGross", "#CFFFF2", "#76f5D2")} onClick={() => setSelectedCard("RevenueGross")}>
                    <span className="dashboard-category">Revenue Gross</span>
                    <Statistic className="dashboard-value" groupSeparator="." value={cardData.revenueGross} precision={0} suffix="€"/>
                </Card>
            )
        },
        {
            key: "EcpmEditore",
            permission: permissionsRole?.canSeeECPMEditoreCard, // Check the specific permission
            component: (
                <Card className="dashboard-card" style={cardStyle("EcpmEditore", "#DDF9E1", "#7fff92")} onClick={() => setSelectedCard("EcpmEditore")}>
                    <span className="dashboard-category">ECPM Editore</span>
                    <Statistic className="dashboard-value" groupSeparator="." value={cardData.ecpmEditore} precision={2} suffix="€"/>
                </Card>
            )
        },
        {
            key: "RevenueEditore",
            permission: permissionsRole?.canSeeRevenueEditore, // Check the specific permission
            component: (
                <Card className="dashboard-card" style={cardStyle("RevenueEditore", "#FFD0E6", "#DC257B")} onClick={() => setSelectedCard("RevenueEditore")}>
                    <span className="dashboard-category">Revenue Editore</span>
                    <Statistic className="dashboard-value" groupSeparator="." value={cardData.revenueEditore} precision={0} suffix="€"/>
                </Card>
            )
        },
        {
            key: "AdRequest",
            permission: true, // Assuming this card is always visible
            component: (
                <Card className="dashboard-card" style={cardStyle("AdRequest", "#F0F4F8", "#7ea7d0")} onClick={() => setSelectedCard("AdRequest")}>
                    <span className="dashboard-category">Ad Request</span>
                    <Statistic className="dashboard-value" groupSeparator="." value={cardData.adRequest} precision={0} />
                </Card>
            )
        },
        {
            key: "Impressions",
            permission: true, // Assuming this card is always visible
            component: (
                <Card className="dashboard-card" style={cardStyle("Impressions", "#ECDAFF", "#b272f5")} onClick={() => setSelectedCard("Impressions")}>
                    <span className="dashboard-category">Paid Impression</span>
                    <Statistic className="dashboard-value" groupSeparator="." value={cardData.paidImpression} precision={0} />
                </Card>
            )
        },
        {
            key: "FillRate",
            permission: true, // Assuming this card is always visible
            component: (
                <Card className="dashboard-card" style={cardStyle("FillRate", "#F6F9DD", "#eefb7c")} onClick={() => setSelectedCard("FillRate")}>
                    <span className="dashboard-category">Fill Rate</span>
                    <Statistic className="dashboard-value" groupSeparator="." value={cardData.fillRate} precision={0} suffix="%"/>
                </Card>
            )
        }
    ];
    
    const orderedCards = cards
                            .filter(card => card.permission) 
                            .sort((a, b) => cardOrder[a.key] - cardOrder[b.key]);

    return (
        <>
            <Loading visible={isLoading} />
            <Drawer width={500} className="filter-drawer" title="Filtri" open={open} onClose={() => setOpen(false)} closeIcon={<IoCloseCircleOutline size={28}/>}>
                <Collapse className="custom-collapse" items={items} expandIconPosition="end"
                    expandIcon={({ isActive }) => isActive ? <img alt="active-accordion" src="/custom-icons/CloseAccordion.svg" /> : <img alt="active-accordion" src="/custom-icons/OpenAccordion.svg" />}
                />
                <Flex justify="right" align="center">
                    <button onClick={() => handleFilter("apply", null)} className="save-button w-100">Applica</button>
                </Flex>
            </Drawer>
            <Navigation>
                <Header>
                    <Flex justify="space-between" align="center">
                        <div>
                            <div className="main-title">{!id ? "Overview" : type === "sito" ? id : dataset?.reports[0]?.publisherName}</div>
                        </div>
                        <div className="filter-section">
                            {id && <BackButton />}
                            <div>
                                <IoCalendarClear className="custom-calendar-icon" />
                                <RangePicker
                                    suffixIcon={<IoCaretDown />}
                                    allowClear={false}
                                    onChange={handleRangeChange}
                                    className="filter date-range-filter"
                                    defaultValue={date}
                                    value={date}
                                    format="DD-MM-YYYY"
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '4px',
                                        borderRadius: '16px',
                                        padding: '0 1rem',
                                        border: 'none',
                                        background: '#8080801f',
                                        color: 'black',
                                        cursor: 'pointer',
                                        height: '2rem'
                                    }}
                                />
                            </div>
                            <div>
                            <Select
                                optionFilterProp="label"
                                style={{
                                    width: '180px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '4px',
                                    borderRadius: '16px',
                                    padding: '0 1rem',
                                    border: 'none',  // Remove internal borders
                                    background: '#8080801f', // Ensuring the background is white.
                                    boxShadow: '0 2px 6px rgba(0,0,0,0.1)', // Adding a subtle shadow for depth.
                                    color: 'black',
                                    fontWeight: 600,
                                    cursor: 'pointer',
                                    height: '2rem',
                                }}
                                suffixIcon={<IoCaretDown style={{ color: 'black' }} />} // Styling the dropdown icon to match
                                placeholder="Preset date"
                                onChange={(value) => handleRangePresets(value)}
                                options={[
                                    {label: "Ultimo anno", value: "year"},
                                    {label: "Ultimo mese", value: "month"},
                                    {label: "Ultima settimana", value: "week"},
                                    {label: "Ultimo giorno", value: "day"},
                                ]}
                            />
                            </div>
                            <button
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '4px',
                                    borderRadius: '16px',
                                    padding: '0 1rem',
                                    border: 'none',
                                    background: '#8080801f',
                                    color: 'black',
                                    cursor: 'pointer',
                                    height: '2rem'
                                }}
                                onClick={() => setOpen(true)}
                            >
                                <IoFilter />
                                Filtri
                            </button>
                        </div>

                    </Flex>
                </Header>
                <div className="main">
                    <div className="page-title">{id ? "Publisher" : "Dashboard"}</div>                
                    <Carousel className="my-carousel" responsive={responsive} removeArrowOnDeviceType={["tablet", "mobile"]}>
                        {orderedCards.map(card => card.component)}
                    </Carousel>
                    <Row gutter={32}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={15}>
                            <Card className="chart-card bar-chart">
                                <span className="chart-title">{cardTitleDecoder(selectedCard)}</span>
                                <BarChart data={chartsData} />
                            </Card>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={9}>
                            <Card className="chart-card pie-chart">
                                <span className="chart-title">{cardTitleDecoder(selectedCard)}</span>
                                <PieChart data={chartsData} />
                            </Card>
                        </Col>
                    </Row>
                    <span className="sub-title">Reports</span>
                    <DashboardTable data={dataset?.reports} setFiltersApi={setFiltersApi} pageInfo={pager} user={loggedUser?.user} permissions={permissionsRole}/>
                </div>
            </Navigation>
        </>
    )
}

export default Dashboard;