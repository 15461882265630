import { Table, Flex, Input } from "antd";
import { IoCreateOutline, IoTrashOutline, IoSearch } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { UserManager } from "../../utils/tools/user-manager/UserManager";
import DeleteConfirm from "../delete-confirm/DeleteConfirm";

const apiUrl = window.AppConfig.API_ENDPOINT

const PublisherTable = ({data, setTrigger}) => {
    const [loggedUser, setLoggedUser] = useState(null);

    useEffect(() => {
        const user = UserManager();
        if (user) {
            setLoggedUser(user);
        }
    }, []);

    const navigate = useNavigate();

    const deleteEntity = (id) => {
        if (loggedUser) {
            axios.delete(`${apiUrl}/api/v1/publisher/delete`, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                },
                data: { id: parseInt(id) } 
            })
            .then(response => {
                setTrigger(new Date());
            })
            .catch(error => {
                console.error('Error:', error);
            });
        }
    }
        

    const columns = [
        {
            title: 'Nome completo',
            dataIndex: 'nome_cognome',
            width: 150,
            render: (row, elem) => <a className="name-col" href={`/dealership/publisher?id=${elem.id}`}>{elem.nome} {elem.cognome}</a>
        },
        {
            title: 'Nome Partner',
            dataIndex: 'nomePartner',
        },
        {
            title: 'PEC Email',
            dataIndex: 'emailPec',
        },
        {
            title: 'Stato',
            dataIndex: 'status',
        },
        {
            title: 'Azioni',
            dataIndex: 'action',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (row, elem) => <Flex gap="small" className="action-menu">
                    <IoCreateOutline size={20} onClick={() => navigate(`/dealership/publisher?id=${elem.id}`)} />
                    <DeleteConfirm action={deleteEntity} id={elem.utentiId} />
                </Flex>
        }
    ];

    const rowClassName = (record, index) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };
    
    return (
        <>
            <Input className="table-search" size="large" placeholder="Ricerca" prefix={<IoSearch />} />
            <Table
                rowKey="id"
                columns={columns}
                dataSource={data}
                rowClassName={rowClassName}
                scroll={{y: "100%", x: 1300}}
            />
        </>
    )
}

export default PublisherTable;