import Navigation from "../../components/navigation/Navigation";
import Layout from "antd/es/layout/layout";
import { Radio, Dropdown, Pagination, Drawer, Flex, Form, Input, Card, Select, Breadcrumb, Upload, Button } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { IoHome, IoSearch } from "react-icons/io5";
import axios from "axios";
import { useState, useEffect } from "react";
import BackButton from "../../components/navigation/BackButton";
import Loading from "../../components/loading/Loading";
import { UserManager } from "../../utils/tools/user-manager/UserManager";
import { FaCaretDown } from "react-icons/fa";
import { IoIosCloseCircleOutline } from "react-icons/io";
import "./style.css"

const { Header } = Layout;
const { TextArea } = Input;

const apiUrl = window.AppConfig.API_ENDPOINT

const NewWidget = () => {
    const [dataset, setDataset] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [form] = Form.useForm();
    const [loggedUser, setLoggedUser] = useState(null);
    const [websiteList, setWebsiteList] = useState([])
    const [tag, setTag] = useState([])
    const [category, setCategory] = useState([])
    const [filtersDrawer, setFiltersDrawer] = useState({tag: [], category: [], text: ""});
    const [filtersApi, setFiltersApi] = useState({
        "Search":"",
        "CategorieId": [],
        "TagsId": [],
        "Page": 1,
    })
    const [pagerDrawer, setPagerDrawer] = useState()
    const [currentPageDrawer, setCurrentPageDrawer] = useState(1)
    const [videoListDrawer, setVideoListDrawer] = useState()
    const [open, setOpen] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState({id: null, url: null})
    const [file, setFile] = useState(null);

    const themes = [
        {value: "treso", label: "Treso (default)"},
        {value: "chrome", label: "Chrome"},
        {value: "jwlike", label: "Jwlike"},
        {value: "party", label: "Party"},
        {value: "roundal", label: "Roundal"},
        {value: "shaka", label: "Shaka"},
        {value: "pinko", label: "Pinko"},
        {value: "flow", label: "Flow"},
        {value: "mockup", label: "Mockup"},
        {value: "slategrey", label: "Slategrey"}
    ]

    useEffect(() => {
        const user = UserManager();
        if (user) {
            setLoggedUser(user);
        }
    }, []);
    
    const { id } = useParams();
    const navigate = useNavigate();

    const itemsVideoDrawer = [
        {
            key: '1',
            label: 'Aggiungi',   
            onClick: () => {
                setOpen(false)
            }        
        },
        {
            key: '2',
            label: 'Annulla',
            onClick: () => {
                setSelectedVideo({id: dataset.videoId, url: dataset.urlVideo})
                setOpen(false)
            }
        },
    ];

    console.log(selectedVideo)

    useEffect(() => {
        if (loggedUser) {
            axios.post(`${apiUrl}/api/v1/init/list-inventory`, {}, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
                .then(response => {
                    const data = response.data
                    const list = data.map(element => ({label: element.text, value: parseInt(element.value)}))
                    setWebsiteList(list)
                })
                .catch(error => {
                    console.error('Error:', error);
                });

            axios.post(`${apiUrl}/api/v1/tagcategorie/get-list-categorie`, {}, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
                .then(response => {
                    const categories = response.data.data.map((r) => ({label: r.nome, value: r.id}))
                    setCategory(categories)
                })
                .catch(error => {
                    console.error('Error:', error);
                });

            axios.post(`${apiUrl}/api/v1/tagcategorie/get-list-tags`, {}, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
                .then(response => {
                    const tags = response.data.data.map((r) => ({label: r.nome, value: r.id}))
                    setTag(tags)
                })
                .catch(error => {
                    console.log('Error:', error);
                });

            if (id) {
                axios.post(`${apiUrl}/api/v1/player/get-detail`, {id : parseInt(id)}, {
                    headers: {
                        'Authorization': `Bearer ${loggedUser.token.accessToken}`
                    }
                })
                    .then(response => {
                        setDataset(response.data)
                        setIsLoading(false)
                    })
                    .catch(error => {
                        console.error('Error:', error);
                        setIsLoading(false)
                    });
            } else {
                setIsLoading(false)
            }
        }
    }, [loggedUser])

    useEffect(() => {
        if (loggedUser) {
            axios.post(`${apiUrl}/api/v1/video/get-list`, filtersApi, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
                .then(response => {
                    setVideoListDrawer(response.data.data);
                    setPagerDrawer(response.data.pager)
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }, [loggedUser, filtersApi])

    useEffect(() => {
        if (dataset && videoListDrawer) {
            setSelectedVideo({id: dataset.videoId, url: dataset.urlVideo})
        }
    }, [videoListDrawer, dataset])

    const handleSubmit = () => {
        if (loggedUser) {
            form.validateFields()
            .then(values => {
                values.Sito = ""
                values.imgPoster = file
                values.videoId = selectedVideo.id
                values.fileUrl = selectedVideo.url
                if (id) {
                    axios.put(`${apiUrl}/api/v1/player/upsert`, {id: parseInt(id), ...values, status: "attivo"}, {
                        headers: {
                            'Authorization': `Bearer ${loggedUser.token.accessToken}`
                        }
                    })
                    .then(response => {
                        navigate(`/widget`)
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
                } else {
                    axios.put(`${apiUrl}/api/v1/player/upsert`, {id: 0, ...values}, {
                        headers: {
                            'Authorization': `Bearer ${loggedUser.token.accessToken}`
                        }
                    })
                    .then(response => {
                        navigate(`/widget`)
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
                }
            })
            .catch(errorInfo => {
                console.log('Error:', errorInfo);
            });
        }
    }

    const pageChangeDrawer = (event) => {
        setCurrentPageDrawer(event)
        setFiltersDrawer(prevState => ({
            ...prevState, 
            Page: event
        }));
    }

    const setVideoFilters = (type, values) => {
        switch (type) {
            case "text": setFiltersDrawer(prevState => ({
                ...prevState, text: values.target.value}))
                break;
            case "tag": setFiltersDrawer(prevState => ({
                ...prevState, tag: values}))
                break;
            case "category": setFiltersDrawer(prevState => ({
                ...prevState, category: values}))
                break;
            case "apply": applyDrawerFilters()
        }
    };

    const applyDrawerFilters = () => {
        setFiltersApi(prevState => ({
            ...prevState, 
                Search:filtersDrawer.text,
                CategorieId: filtersDrawer.category,
                TagsId: filtersDrawer.tag,
            }))
    }

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const handleBeforeUpload = (file) => {
        getBase64(file, (base64String) => {
            setFile(base64String);
        });
        return false;  
    };

    return (
        <>
            <Loading visible={isLoading} />
            <div className="video-drawer">
                <Drawer style={{borderRadius: "65px 65px 0 0"}} height="90%" className="video-drawer" placement="bottom" onClose={() => {setOpen(false); setSelectedVideo({id: dataset.videoId, url: dataset.urlVideo})}} open={open} closeIcon={<img alt="close-drawer" className="close-drawer-video" src="/custom-icons/CloseDrawer.svg" />}>
                    <Flex justify="center"><h1>Aggiungi Video</h1></Flex>
                    <div className="video-selector-drawer">
                        <Form
                            layout="inline"
                            name="anagrafica"
                            style={{width: "100%"}}
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={() => setOpen(true)}
                            validateMessages={{required: false}}
                        >
                            <Flex style={{width: "100%"}} align="flex-start" gap={40} wrap>
                                <Form.Item
                                    name="titolo"
                                    style={{width: "30%"}}
                                >
                                    <Input onChange={(val) => setVideoFilters("text", val)} placeholder="Ricerca" prefix={<IoSearch />}  />
                                </Form.Item>
                                <Form.Item
                                    name="category"
                                    style={{width: "25%"}}
                                >
                                    <Select
                                        optionFilterProp="label"
                                        mode="multiple"
                                        allowClear
                                        placeholder="Select category"
                                        options={category}
                                        onChange={(val) => setVideoFilters("category", val)}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="tags"
                                    style={{width: "25%"}}
                                >
                                    <Select
                                        optionFilterProp="label"
                                        mode="multiple"
                                        allowClear
                                        placeholder="Select tags"
                                        options={tag}
                                        onChange={(val) => setVideoFilters("tag", val)}
                                    />
                                </Form.Item>
                                <button style={{width: "10%"}} onClick={() => setVideoFilters("apply", null)} className="add-button">Cerca</button>
                            </Flex>
                        </Form>
                        {
                            selectedVideo &&
                            <div className="playlist-action action-drawer">
                                <Flex gap="large">
                                    <Dropdown menu={{items: itemsVideoDrawer}}>
                                        <div>
                                            Azioni <FaCaretDown />
                                        </div>
                                    </Dropdown>
                                </Flex>
                                <Button onClick={() => setSelectedVideo({id: dataset.videoId, url: dataset.urlVideo})} className="close-icon">
                                    <IoIosCloseCircleOutline size={20} />
                                </Button>
                            </div>
                        }
                        <div className="card-container">
                            <div className="custom-row">
                                {videoListDrawer?.map((item) => (
                                    <Card
                                        key={`video-select-${item.id}`}
                                        className="custom-col video-card video-selector"
                                        cover={
                                            <div className="image-container">
                                                <video
                                                    className="video-content"
                                                    controls={true}
                                                >
                                                    <source src={item.fileUrl} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                                <div className="overlay-checkbox">
                                                    <Radio onChange={() => setSelectedVideo({id: item.id, url: item.fileUrl})}
                                                        className="custom-checkbox" checked={selectedVideo.id === item.id}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    >
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <b>{item.titolo}</b>
                                        </div>
                                        <div className="tag-section">
                                            {item.tags.map(element => tag.find(t => t.value === element)?.label)}
                                        </div>
                                    </Card>
                                ))}
                            </div>
                        </div>
                    </div>
                    <Pagination style={{paddingTop: 30}} defaultPageSize={20} current={currentPageDrawer} onChange={pageChangeDrawer} total={pagerDrawer?.totalItems} />
                </Drawer>
            </div>
            <Navigation>
                <Header>
                    <Flex justify="space-between" align="center">
                        <div>
                            <div className="main-title">{id ? "Modifica Widget" : "Crea Widget"}</div>
                        </div>
                        <div className="filter-section">
                            <BackButton />
                            <div>
                                <button className="add-button" onClick={() => handleSubmit()}>Salva</button>
                            </div>
                        </div>
                    </Flex>
                </Header>
                <div className="main">
                    <Breadcrumb
                        separator=">"
                        items={[
                        {
                            href: "/",
                            title: (
                                <Flex gap="small" align="center">
                                    <IoHome />
                                    Home
                                </Flex>
                            ),
                        },
                        {
                            href: "/widget",
                            title: 'Widget',
                        },
                        {
                            title: id ? "Modifica Widget" : "Crea Widget",
                            className: 'active-breadcrumb'
                        }
                        ]}
                    />
                    <Card title="Anagrafica" className="form-card">
                        <Flex vertical gap="large">
                            <Form
                                layout="inline"
                                name="widget"
                                form={form}
                                validateMessages={{required: false}}
                                fields={[
                                    {
                                        name: ["webSitesId"],
                                        value: dataset?.webSitesId
                                    },
                                    {
                                        name: ["contentId"],
                                        value: dataset?.contentId
                                    },
                                    {
                                        name: ["playerTheme"],
                                        value: dataset?.playerTheme || "treso"
                                    },
                                    {
                                        name: ["isDebug"],
                                        value: dataset?.isDebug || false
                                    },
                                    {
                                        name: ["isPortrait"],
                                        value: dataset?.isPortrait || false
                                    },
                                    {
                                        name: ["urlVideo"],
                                        value: selectedVideo?.url
                                    },
                                    {
                                        name: ["imgPoster"],
                                        value: dataset?.imgPoster
                                    },
                                    {
                                        name: ["adsUrl"],
                                        value: dataset?.adsUrl
                                    }
                                ]}

                            >
                                <Flex vertical gap="large" style={{ width: "100%" }}>
                                    <Flex vertical gap="small">
                                        <Flex style={{ width: "100%" }}>
                                            <Form.Item
                                                name="webSitesId"
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                                style={{ flex: 1 }}
                                            >
                                                <Select
                                                    optionFilterProp="label"
                                                    showSearch
                                                    options={websiteList}
                                                    placeholder="Sito"
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                name="contentId"
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                                style={{ flex: 1 }}
                                            >
                                                <Input placeholder="Content Id" />
                                            </Form.Item>

                                            <Form.Item
                                                name="playerTheme"
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                                style={{ flex: 1 }}
                                            >
                                                <Select
                                                    optionFilterProp="label"
                                                    showSearch
                                                    options={themes}
                                                    placeholder="Tema"
                                                    defaultValue={"treso"}
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                name="isDebug"
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                                style={{ flex: 1 }}
                                            >
                                                <Select
                                                    optionFilterProp="label"
                                                    showSearch
                                                    options={[{ value: true, label: "Debug Attivo" }, { value: false, label: "Debug non attivo" }]}
                                                    placeholder="Debug"
                                                    defaultValue={false}
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                name="isPortrait"
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                                style={{ flex: 1 }}
                                            >
                                                <Select
                                                    optionFilterProp="label"
                                                    showSearch
                                                    options={[{ value: true, label: "Portrait Attivo" }, { value: false, label: "Portrait non attivo" }]}
                                                    placeholder="Portrait"
                                                    defaultValue={false}
                                                />
                                            </Form.Item>
                                        </Flex>

                                        <Form.Item
                                            name="urlVideo"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Flex>
                                                <button className="select-video-button" onClick={() => setOpen(true)}>Aggiungi video</button>
                                                <Input style={{paddingLeft: 33, marginLeft: "-20px", background: "white", color: "black"}} placeholder="URL Video" disabled value={selectedVideo?.url} />
                                            </Flex>
                                        </Form.Item>

                                        <Form.Item
                                            name="imgPoster"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                            style={{ flex: 1 }}
                                        >
                                            <Upload name='file'
                                                accept='.png,.jpg'
                                                beforeUpload={handleBeforeUpload}
                                                onRemove={() => setFile(null)}
                                                className="upload-poster"
                                            >
                                                <Flex align="center" gap="large">
                                                    <Button className="select-video-button">Seleziona immagine</Button>
                                                    <div className="poster-container">{dataset?.imgPoster}</div>
                                                </Flex>
                                            </Upload>     
                                        </Form.Item>

                                        <Form.Item
                                            name="adsUrl"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <TextArea rows={3} placeholder="Ads URL" />
                                        </Form.Item>
                                    </Flex>
                                </Flex>
                            </Form>
                        </Flex>
                    </Card>

                </div>
            </Navigation>
        </>
    )
}

export default NewWidget;