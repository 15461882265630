import { FaPlayCircle } from "react-icons/fa";
import { MdPlaylistAddCircle } from "react-icons/md";
import { BiSolidWidget } from "react-icons/bi";
import { IoMdSettings } from "react-icons/io";
import { UserManager } from "../tools/user-manager/UserManager";
import { useState, useEffect } from "react";
import { permissions } from "../tools/roles/roles";

export const VideoMenuItems = () => {
    const [permissionsRole, setPermissionsRole] = useState()

    const showVideoSection = window.AppConfig.SHOW_VIDEO_SECTION

    useEffect(() => {
        const user = UserManager();
        if (user) {
            setPermissionsRole(permissions[user.user.ruolo]);
        }
    }, []);

    const menuItems = [
        {
            key: "video",
            icon: <FaPlayCircle />,
            label: "Video",
            permission: showVideoSection
        },
        {
            key: "playlist",
            icon: <MdPlaylistAddCircle />,
            label: "Playlist",
            permission: showVideoSection
        },
        {
            key: "widget",
            icon: <BiSolidWidget />,
            label: "Widget",
            permission: permissionsRole?.canSeeWidgetMenu
        },
        {
            key: "settings",
            icon: <IoMdSettings />,
            label: "Settings",
            permission: showVideoSection && permissionsRole?.canSeeSettings,
            children: [
                {
                    key: 'tag',
                    label: 'Tags',
                },
                {
                    key: 'category',
                    label: 'Categorie',
                }
            ]
        },
    ]

    return menuItems
                .filter(item => item.permission)
                .map(({ permission, ...rest }) => rest);
}