import toast from 'react-hot-toast';

export const cardTitleDecoder = (type) => {
    switch(type) {
        case "EcpmGross": return "ECPM Gross"
        case "AdRequest": return "Ad Request"
        case "Impressions": return "Paid Impression"
        case "FillRate": return "Fill Rate"
        case "EcpmEditore": return "ECPM Editore"
        case "RevenueGross": return "Revenue Gross"
        case "RevenueEditore": return "Revenue Editore"
        default: return "ECPM Gross"
    }
}

export const convertFloat = (numberString) => {
    if (typeof numberString === 'number') {
        return numberString; 
    }

    if (typeof numberString === 'string') {
        return parseFloat(numberString.replace(',', '.'));
    }

    return NaN;
}

export const convertStringFloat = (float) => float.toString().replace('.', ',');

export const notify = (message) => toast(message);
