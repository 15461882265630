import { useState, useEffect } from "react";
import Navigation from "../../components/navigation/Navigation";
import Layout from "antd/es/layout/layout";
import { Popconfirm, Flex, Card, Form, Select, Input, Upload, Checkbox, Dropdown, Button, Breadcrumb, Pagination } from "antd";
import { IoIosCreate } from "react-icons/io";
import { FaListUl } from "react-icons/fa";
import { FaCaretDown } from "react-icons/fa";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { IoCloseCircle, IoHome, IoSearch } from "react-icons/io5";
import BackButton from "../../components/navigation/BackButton";
import axios from 'axios';
import { UserManager } from '../../utils/tools/user-manager/UserManager';
import Loading from "../../components/loading/Loading";
import { useParams, useNavigate } from "react-router-dom";
import { notify } from "../../utils/tools/Tools";

const { Header } = Layout;
const { TextArea } = Input;

const apiUrl = window.AppConfig.API_ENDPOINT

const VideoDetails = () => {
    const { id } = useParams();

    const [file, setFile] = useState(null);
    const [dataset, setDataset] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [loggedUser, setLoggedUser] = useState(null);
    const [selectedPlaylists, setSelectedPlaylists] = useState([]);
    const [tag, setTag] = useState([])
    const [filtersApi, setFiltersApi] = useState({
        "Search":"",
        "VideoId": parseInt(id),
        "Page": 1,
    })    
    const [pager, setPager] = useState()
    const [currentPage, setCurrentPage] = useState(1)
    const [category, setCategory] = useState([])
    const [playlistData, setPlaylistData] = useState([])
    const [checkAll, setCheckAll] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [trigger, setTrigger] = useState()

    const navigate = useNavigate()

    useEffect(() => {
        const user = UserManager();
        if (user) {
            setLoggedUser(user);
        }
    }, []);

    useEffect(() => {
        if (loggedUser) {
            axios.post(`${apiUrl}/api/v1/video/get-playlist`, filtersApi, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
                .then(response => {
                    setPlaylistData(response.data.data);
                    setIsLoading(false);
                })
                .catch(error => {
                    console.error('Error:', error);
                    setIsLoading(false);
                });
        }
    }, [filtersApi, loggedUser, trigger]);

    useEffect(() => {
        if (loggedUser) {
            axios.post(`${apiUrl}/api/v1/video/get-detail`, {id: parseInt(id)}, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
                .then(response => {
                    setDataset(response.data.data);
                    setPager(response.data.pager);
                    setIsLoading(false);
                })
                .catch(error => {
                    console.error('Error:', error);
                    setIsLoading(false);
                });

            axios.post(`${apiUrl}/api/v1/tagcategorie/get-list-categorie`, {}, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
                .then(response => {
                    const categories = response.data.data.map((r) => ({label: r.nome, value: r.id}))
                    setCategory(categories)
                })
                .catch(error => {
                    console.error('Error:', error);
                });

            axios.post(`${apiUrl}/api/v1/tagcategorie/get-list-tags`, {}, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
                .then(response => {
                    const tags = response.data.data.map((r) => ({label: r.nome, value: r.id}))
                    setTag(tags)
                })
                .catch(error => {
                    console.log('Error:', error);
                });
        }
    }, [loggedUser, trigger])

    const handleChange = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchQuery(value);
    };

    useEffect(() => {
        if (searchQuery.length > 2) {
            setFiltersApi(prevState => ({
                ...prevState, 
                Search: searchQuery
            }));
        }

        if (searchQuery.length === 0) {
            setFiltersApi(prevState => ({
                ...prevState, 
                Search: ""
            }));
        } 
    }, [searchQuery])

    const itemsPlaylist = [
        {
            key: '1',
            label: <Popconfirm
                        title="Elimina elemento"
                        description="Sicuro di voler eliminare questo elemento?"
                        okButtonProps={{style: {backgroundColor: "#CE2472"}}}
                        onConfirm={() => removeVideoFromPlaylist()}
                        okText="Si"
                        cancelText="No"
                    >
                        Rimuovi
                    </Popconfirm>,
        },
        {
            key: '2',
            label: 'Annulla',
            onClick: () => setSelectedPlaylists([])
        },
    ];

    const removeVideoFromPlaylist = () => {
        setIsLoading(true)
        axios.put(`${apiUrl}/api/v1/video/remove-playlist`, {id: parseInt(id), playlistId: selectedPlaylists}, {
            headers: {
                'Authorization': `Bearer ${loggedUser.token.accessToken}`
            }
        })
            .then(response => {
                setIsLoading(false)
                notify("Video rimosso dalla/e Playlist selezionate")
                setSelectedPlaylists([])
                setTrigger(new Date())
            })
            .catch(error => {
                setIsLoading(false)
                console.error('Error:', error);
            });
    }
    
    const handleCheckboxChange = (event, id) => {
        if (event.target.checked) {
            setSelectedPlaylists([...selectedPlaylists, id]);
        } else {
            setSelectedPlaylists(selectedPlaylists.filter(item => item !== id));
        }
    };

    const handleCheckAllChange = (event) => {
        const { checked } = event.target;
        setCheckAll(checked);
        if (checked) {
            const allIds = playlistData.map(playlist => playlist.id);
            setSelectedPlaylists(allIds);
        } else {
            setSelectedPlaylists([]);
        }
    };

    const handleUploadVideo = (values) => {
        if (loggedUser) {
            setIsLoading(true);
            let fileUrl = dataset.fileUrl
            let fileSize = dataset.dimensione
            if (file) {
                const formData = new FormData();
                formData.append('file', file);

                axios.post(`${apiUrl}/api/v1/video/upload/${id}`, formData, {
                    headers: {
                        'Authorization': `Bearer ${loggedUser.token.accessToken}`,
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then(response => {
                    setIsLoading(false)
                    notify("Modifiche apportate con successo")
                    setTrigger(new Date())
                    fileUrl = response.data.data.fileUrl
                    fileSize = response.data.data.dimensione
                })
                .catch(error => {
                    setIsLoading(false)
                    console.error('Error:', error);
                });
            }

            const data = {
                "id": parseInt(id),
                "titolo": values.titolo,
                "descrizione": values.descrizione,
                "fileUrl": fileUrl,
                "categoriaId": values.categoriaId,
                "tags": values.tags,
                "dimensione": fileSize,
                "utentiId": loggedUser.user.id,
                "status": "attivo"
            }

            axios.put(`${apiUrl}/api/v1/video/upsert`, data, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`,
                },
            })
            .then(response => {
                setIsLoading(false)
                notify("Modifiche apportate con successo")
                setTrigger(new Date())
            })
            .catch(error => {
                console.error('Error:', error);
                setIsLoading(false)
            });
            setFile(null);
            
        }
    }

    const deleteEntity = () => {
        if (loggedUser) {
            axios.delete(`${apiUrl}/api/v1/video/delete`, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                },
                data: { id: parseInt(id) } 
            })
            .then(response => {
                navigate("/video");
            })
            .catch(error => {
                console.error('Error:', error);
            });
        }
    }

    const pageChange = (event) => {
        setCurrentPage(event)
        setFiltersApi(prevState => ({
            ...prevState, 
            Page: event
        }));
    }

    return (
        <>
            <Loading visible={isLoading} />
            <Navigation>
                <Header>
                    <Flex justify="space-between" align="center">
                        <div>
                            <div className="main-title">Dettagli Video</div>
                        </div>
                        <div className="filter-section">
                            <BackButton />
                            <Popconfirm
                                title="Elimina elemento"
                                description="Sicuro di voler eliminare questo elemento?"
                                okButtonProps={{style: {backgroundColor: "#CE2472"}}}
                                onConfirm={() => deleteEntity()}
                                okText="Si"
                                cancelText="No"
                            >
                                <button style={{width: "167px"}} className="print-button">
                                    Elimina
                                    <IoCloseCircle size={15}/>
                                </button>
                            </Popconfirm>
                        </div>
                    </Flex>
                </Header>
                <div className="main">
                    <Breadcrumb
                        separator=">"
                        items={[
                        {
                            href: "/",
                            title: (
                                <Flex gap="small" align="center">
                                    <IoHome />
                                    Home
                                </Flex>
                            ),
                        },
                        {
                            href: "/video",
                            title: 'Video',
                        },
                        {
                            title: "Dettaglio",
                            className: 'active-breadcrumb'
                        }
                        ]}
                    />
                    <Flex gap="small">
                        <div className="file-video-card" style={{position: "relative "}}>
                            {
                                dataset &&
                                <>
                                    <video className="video-content" controls={true} style={{borderRadius: 8}}>
                                        <source src={dataset.fileUrl} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                    <div className="photo-editor">
                                        <Upload name='file'
                                                accept='video/*'
                                                beforeUpload={(file) => {
                                                    setFile(file);
                                                    return false;
                                                }}
                                                onRemove={() => setFile(null)}
                                        >
                                            <IoIosCreate size={30} />
                                        </Upload>  
                                    </div>
                                </>                                
                            }
                        </div>
                        <Card className="form-card file-video-edit-card" title="Dati Video">
                            <Form
                                layout="inline"
                                name="anagrafica"
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={handleUploadVideo}
                                validateMessages={{required: false}}
                                fields={[
                                    {
                                        name: ["titolo"],
                                        value: dataset?.titolo
                                    },
                                    {
                                        name: ["categoriaId"],
                                        value: dataset?.categoriaId
                                    },
                                    {
                                        name: ["tags"],
                                        value: dataset?.tags
                                    },
                                    {
                                        name: ["descrizione"],
                                        value: dataset?.descrizione
                                    }
                                ]}
                            >
                                <Flex vertical gap="large" style={{width: "100%"}}>
                                    <Flex>
                                        <Form.Item
                                            name="titolo"
                                            style={{flex: 1}}
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Titolo" />
                                        </Form.Item>

                                        <Form.Item
                                            name="categoriaId"
                                            style={{flex: 1}}
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Select
                                                optionFilterProp="label"
                                                allowClear
                                                placeholder="Seleziona categoria"
                                                options={category}
                                            />
                                        </Form.Item>
                                    </Flex>
                                        <Form.Item
                                            name="tags"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Select
                                                optionFilterProp="label"
                                                mode="multiple"
                                                allowClear
                                                placeholder="Select tags"
                                                options={tag}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="descrizione"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <TextArea rows={3} placeholder="Descrizione" />
                                        </Form.Item>                                 
                                    <Flex justify="right" align="center">
                                        <button htmltype="submit" className="save-button">Salva</button>
                                    </Flex>
                                </Flex>   
                            </Form>
                        </Card>
                    </Flex>
                    <Flex vertical>
                        {
                            selectedPlaylists.length > 0 ?
                            <div className="playlist-action">
                                <Flex gap="large">
                                    <Checkbox
                                        onChange={handleCheckAllChange}
                                        checked={checkAll}
                                        className="check-all-checkbox"
                                    >
                                        <div className="select-all">{checkAll ? "Deseleziona tutto" : "Seleziona tutto"}</div>
                                    </Checkbox>
                                    <Dropdown menu={{items: itemsPlaylist}}>
                                        <div>
                                            Azioni <FaCaretDown />
                                        </div>
                                    </Dropdown>
                                </Flex>
                                <Button onClick={() => {setCheckAll(false); setSelectedPlaylists([])}} className="close-icon">
                                    <IoIosCloseCircleOutline size={20} />
                                </Button>
                            </div>
                            : <div className="playlist-title">Playlist in cui è incluso</div>
                        }
                        <Input onChange={handleChange} value={searchQuery} className="table-search playlist-search" size="large" placeholder="Ricerca" prefix={<IoSearch />} />
                        <div className="playlist-container">
                            {
                                playlistData?.map((item) => (
                                    <Flex key={item.id} className="playlist-section" gap="large" align="center">
                                        <Card className="file-video-card preview-playlist" style={
                                            {
                                                background: `url(https://dambe.ideadocet.com${item.copertina})`,
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "cover"
                                            }
                                        }>
                                            <Checkbox onChange={(event) => handleCheckboxChange(event, item.id)}
                                                className="custom-checkbox-adjusted custom-checkbox" checked={selectedPlaylists.includes(item.id)}
                                            />
                                            <div className="overlay overlay-video-details">
                                                <Flex vertical className="icon">
                                                    {item.video.length}
                                                    <FaListUl />
                                                </Flex>
                                            </div>
                                        </Card>
                                        <Flex vertical>
                                            <div className="title">{item.titolo}</div>
                                            <div className="description">{item.descrizione}</div>
                                        </Flex>
                                    </Flex>
                                ))
                            }
                        </div>

                        <Pagination style={{paddingTop: 30}} defaultPageSize={20} current={currentPage} onChange={pageChange} total={pager?.totalItems} />
                        
                    </Flex>
                </div>
            </Navigation>
        </>
    )
}

export default VideoDetails;