import { useState, useEffect } from "react";
import Navigation from "../../components/navigation/Navigation";
import Layout from "antd/es/layout/layout";
import { Flex, Form, Select, Input, Collapse, Card, InputNumber, Button, Breadcrumb, TreeSelect } from 'antd';
import "./style.css"
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IoHome } from "react-icons/io5";
import axios from "axios";
import Loading from "../../components/loading/Loading";
import BackButton from "../../components/navigation/BackButton";
import { UserManager } from "../../utils/tools/user-manager/UserManager";

const { Header } = Layout;

const apiUrl = window.AppConfig.API_ENDPOINT

const New = () => {
    const [dataset, setDataset] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [form] = Form.useForm();
    const [loggedUser, setLoggedUser] = useState(null);
    const location = useLocation();
    const str = location.pathname;
    const parts = str.split('/');
    const page = parts[1];
    
    useEffect(() => {
        const user = UserManager();
        if (user) {
            setLoggedUser(user);
        }
    }, []);

    const handleSubmit = () => {
        if (loggedUser) {
            form.validateFields()
            .then(values => {
                const id = type === "new" ? 0 : parseInt(type)
                const section = page === "tag" ? "tag" : "categoria"
                axios.put(`${apiUrl}/api/v1/tagcategorie/upsert-${section}`, {id: parseInt(id), status: "attivo", ...values}, {
                    headers: {
                        'Authorization': `Bearer ${loggedUser.token.accessToken}`
                    }
                })
                .then(response => {
                    navigate(`/${page}`)
                })
                .catch(error => {
                    console.error('Error:', error);
                });
            })
            .catch(errorInfo => {
                console.log('Error:', errorInfo);
            });
        }
    }

    const navigate = useNavigate();
    
    const { type } = useParams();

    useEffect(() => {
        if (loggedUser) {
            if (type !== "new") {
                const id = type
                const section = page === "tag" ? "tags" : "categorie"
                axios.post(`${apiUrl}/api/v1/tagcategorie/get-detail-${section}`, {id: parseInt(id)}, {
                    headers: {
                        'Authorization': `Bearer ${loggedUser.token.accessToken}`
                    }
                })
                    .then(response => {
                        setDataset(response.data)
                        setIsLoading(false)
                    })
                    .catch(error => {
                        console.error('Error:', error);
                        setIsLoading(false)
                    });
            }
            setIsLoading(false)
        }
    }, [loggedUser])

    return (
        <>
            <Loading visible={isLoading} />
            <Navigation>
                <Header>
                    <Flex justify="space-between" align="center">
                        <div>
                            <div className="main-title">{dataset ? dataset?.nome : `Crea ${page.charAt(0).toUpperCase() + page.slice(1)}`}</div>
                        </div>
                        <div className="filter-section">
                            <BackButton />
                            <button onClick={handleSubmit} htmltype="submit" className="add-button">Salva</button>
                        </div>
                    </Flex>
                </Header>
                <div className="main">
                    <Breadcrumb
                        separator=">"
                        items={[
                            {
                                href: "/",
                                title: (
                                    <Flex gap="small" align="center">
                                        <IoHome />
                                        Home
                                    </Flex>
                                ),
                            },
                            {
                                href: `/${page}`,
                                title: page.charAt(0).toUpperCase() + page.slice(1),
                            },
                            {
                                title: dataset ? dataset?.nome : `Crea ${page.charAt(0).toUpperCase() + page.slice(1)}`,
                                className: 'active-breadcrumb'
                            }
                        ]}
                    />   
                    <Card title={dataset ? dataset?.nome : `${page.charAt(0).toUpperCase() + page.slice(1)}`} className="form-card">
                        <Form
                            form={form}
                            layout="inline"
                            name="tag"
                            onFinish={handleSubmit}
                            validateMessages={{required: false}}
                            fields={[
                                {
                                    name: ["nome"],
                                    value: dataset?.nome
                                },
                                {
                                    name: ["descrizione"],
                                    value: dataset?.descrizione
                                }
                            ]}
                        >
                            <Flex>
                                <Form.Item
                                    name="nome"
                                    style={{width: "100%"}}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input placeholder="Nome" />
                                </Form.Item>

                                <Form.Item
                                    name="descrizione"
                                    style={{width: "100%"}}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input placeholder="Descrizione" />
                                </Form.Item>
                            </Flex>
                        </Form>
                    </Card>                  
                </div>
            </Navigation>
        </>
    )
}

export default New;