import { HiMiniSquares2X2 } from "react-icons/hi2";
import { IoPeople, IoStatsChartSharp } from "react-icons/io5";
import { TbWorld } from "react-icons/tb";
import { permissions } from "../tools/roles/roles";
import { UserManager } from "../tools/user-manager/UserManager";
import { useState, useEffect } from "react";

export const GeneralMenuItems = () => {
    const [permissionsRole, setPermissionsRole] = useState()

    useEffect(() => {
        const user = UserManager();
        if (user) {
            setPermissionsRole(permissions[user.user.ruolo]);
        }
    }, []);

    const filterMenuItems = (items) => {
        return items
            .filter(item => item.permission)
            .map(({ permission, children, ...rest }) => {
                if (children) {
                    return {
                        ...rest,
                        children: filterMenuItems(children)
                    };
                }
                return rest;
            });
    };

    const menuItems = [
        {
            key: "",
            icon: <HiMiniSquares2X2 />,
            label: "Dashboard",
            permission: true
        },
        {
            key: "dealership",
            icon: <IoPeople />,
            label: "Concessionarie",
            permission: permissionsRole?.canSeeConcessionariaMenu
        },
        {
            key: "inventory",
            icon: <TbWorld />,
            label: "Inventory",
            permission: permissionsRole?.canSeeInventoryMenu
        },
        {
            key: "report_main",
            icon: <IoStatsChartSharp />,
            label: "Report",
            permission: true,
            children: [
                {
                    key: 'report_upload',
                    label: 'Upload Report',
                    permission: permissionsRole?.canSeeReportUpload
                },
                {
                    key: 'report',
                    label: 'Reports',
                    permission: true
                }
            ]
        },
    ]

    return filterMenuItems(menuItems);
}