import { Table, Input, Flex } from "antd";
import { IoCreateOutline, IoTrashOutline, IoSearch } from "react-icons/io5";
import { useState, useEffect } from "react";
import axios from "axios";
import { UserManager } from "../../utils/tools/user-manager/UserManager";
import { useNavigate } from "react-router-dom";
import DeleteConfirm from "../delete-confirm/DeleteConfirm";

const apiUrl = window.AppConfig.API_ENDPOINT

const CategoryTable = ({data, trigger}) => {
    const [tableData, setTableData] = useState(data)
    const [searchQuery, setSearchQuery] = useState('');
    const [loggedUser, setLoggedUser] = useState(null);

    const navigate = useNavigate();
    
    useEffect(() => {
        const user = UserManager();
        if (user) {
            setLoggedUser(user);
        }
    }, []);

    useEffect(() => {
        setTableData(data)
    }, [data])

    const handleChange = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchQuery(value);
    };

    const deleteEntity = (id) => {
        if (loggedUser) {
            axios.delete(`${apiUrl}/api/v1/tagcategorie/delete-categoria`, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                },
                data: { id: parseInt(id) } 
            })
            .then(response => {
                trigger(new Date());
            })
            .catch(error => {
                console.error('Error:', error);
            });
        }
    }

    const columns = [
        {
            title: 'Nome',
            dataIndex: 'nome',
        },
        {
            title: 'Descrizione',
            dataIndex: 'descrizione',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (row, elem) => elem.status ? <div className="active-col">Attivo</div> : <div className="inactive-col">Disattivo</div>
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (row, elem) => <Flex gap="small" className="action-menu">
                    <IoCreateOutline size={20} onClick={() => navigate(`/category/${elem.id}`)} />
                    <DeleteConfirm action={deleteEntity} id={elem.id} />
                </Flex>
        }
    ];

    const rowClassName = (record, index) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };
    
    return (
        <>
            <Input value={searchQuery} onChange={handleChange} className="table-search" size="large" placeholder="Ricerca" prefix={<IoSearch />} />
            <Table
                rowKey="id"
                columns={columns}
                dataSource={tableData}
                scroll={{y: "100%", x: 1300}}
                rowClassName={rowClassName}
            />
        </>
    )
}

export default CategoryTable;