import { Card, Flex, Form, Input } from "antd"
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import Cookies from 'js-cookie';
import "./style.css"

const Login = () => {  
    const apiUrl = window.AppConfig.API_ENDPOINT
    const navigate = useNavigate()

    const onFinish = (userData) => {
        axios.post(`${apiUrl}/api/v1/auth/login`, userData)
            .then(response => {
                Cookies.set('loggedUser', JSON.stringify(response.data), { expires: 1 });
                navigate("/");
            })
            .catch(error => {
                console.error('Error:', error);
                alert('Failed to login. Please try again.');
            });
    }

    return (
        <Flex vertical justify="center" align="center" gap="large">
            <img src="/logo.svg" alt="login" className="login-logo"/>
            <Card className="login-card">
                <div className="login-title">Login</div>
                <Form
                    validateMessages={{required: false}}
                    layout="inline"
                    name="login"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                >
                    <Flex align="center" vertical gap="large" style={{width: "100%"}}>
                        <Flex vertical align="center" gap="large" style={{width: "100%"}}>
                            <Form.Item
                                name="username"
                                className="login-form"
                                style={{width: "70%"}}
                                rules={[
                                {
                                    required: true,
                                },
                                ]}
                                hasFeedback
                            >
                                <Input placeholder="Username" />
                            </Form.Item>
                            <Form.Item
                                name="Password"
                                style={{width: "70%"}}
                                rules={[
                                {
                                    required: true,
                                },
                                ]}
                                hasFeedback
                            >
                                <Input.Password placeholder="Password" />
                            </Form.Item>
                            <div className="forgot-password">Hai dimenticato la password? <a href="/">clicca qui</a></div>
                        </Flex>
                        <Flex justify="center" align="center" style={{width: "70%"}}>
                            <button className="add-button login-button">Login</button>
                        </Flex>
                    </Flex>
                </Form>
            </Card>
        </Flex>
    )
}

export default Login;