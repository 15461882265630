import { Table, Flex, Input } from "antd";
import { IoCreateOutline, IoTrashOutline, IoSearch } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import axios from "axios";
import "./style.css"
import { UserManager } from "../../utils/tools/user-manager/UserManager";
import DeleteConfirm from "../delete-confirm/DeleteConfirm";

const apiUrl = window.AppConfig.API_ENDPOINT

const WidgetTable = ({data, setFiltersApi, pageInfo, trigger}) => {
    const [tableData, setTableData] = useState(data)
    const [searchQuery, setSearchQuery] = useState('');
    const [loggedUser, setLoggedUser] = useState(null);

    useEffect(() => {
        const user = UserManager();
        if (user) {
            setLoggedUser(user);
        }
    }, []);

    const handleChange = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchQuery(value);
    };

    useEffect(() => {
        setTableData(data)
    }, [data])

    const deleteEntity = (id) => {
        if (loggedUser) {
            axios.delete(`${apiUrl}/api/v1/player/delete`, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                },
                data: { id: parseInt(id) } 
            })
            .then(response => {
                trigger(new Date());
            })
            .catch(error => {
                console.error('Error:', error);
            });
        }
    }

    useEffect(() => {
        if (searchQuery.length > 2) {
            setFiltersApi(prevState => ({
                ...prevState, 
                Search: searchQuery
            }));
        }

        if (searchQuery.length === 0) {
            setFiltersApi(prevState => ({
                ...prevState, 
                Search: ""
            }));
        } 
    }, [searchQuery])

    const navigate = useNavigate();

    const columns = [
        {
            title: 'Date',
            dataIndex: 'dtCreazione',
            width: 150,
            render: (row, elem) => dayjs(elem.dtCreazione).format("DD/MM/YYYY")
        },
        {
            title: 'Site',
            dataIndex: 'sito',
            className: "name-col",
            width: 250,
            render: (row, elem) => <a className="name-col" href={`/widget/${elem.id}`}>{elem.sito}</a>
        },
        {
            title: 'ContentId',
            dataIndex: 'contentId',
        },
        {
            title: 'Portrait',
            dataIndex: 'isPortrait',
            render: (row, elem) => elem.isPortrait.toString() 
        },
        {
            title: 'Theme',
            dataIndex: 'playerTheme',
        },
        {
            title: 'Widget',
            dataIndex: 'widget',
            render: (row, elem) => <div className="widget-col" onClick={() => {
                if (navigator.clipboard) {
                    navigator.clipboard.writeText(elem.widgetScript)
                        .then(() => alert("Contenuto copiato"))
                        .catch(err => console.error("Failed to copy content: ", err));
                } else {
                    console.error("Clipboard functionality not available.");
                }
            }}>Get Widget</div>
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (row, elem) => elem.status === "attivo" ? <div className="active-col">Attivo</div> : <div className="inactive-col">Disattivo</div>
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (row, elem) => <Flex gap="small" className="action-menu" justify="flex-end">
                    <IoCreateOutline size={20} onClick={() => navigate(`/widget/${elem.id}`)} />
                    <DeleteConfirm action={deleteEntity} id={elem.id} />
                </Flex>
        }
    ];
    
    const rowClassName = (record, index) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };

    const totalElements = pageInfo?.totalItems;
    
    return (
        <>
            <Input value={searchQuery} onChange={handleChange} className="table-search" size="large" placeholder="Ricerca" prefix={<IoSearch />} />
            <Table
                rowKey="id"
                columns={columns}
                dataSource={tableData}
                pagination={{
                    pageSize: pageInfo?.pageSize,
                    total: totalElements,
                }}
                scroll={{y: "100%", x: 1300}}
                rowClassName={rowClassName}
                pageSize={pageInfo?.pager?.totalPages}
                onChange={(data) => {
                    setFiltersApi(prevState => ({
                        ...prevState, 
                        Page: data.current,
                        Size: data.pageSize
                    }));
                }}
            />
        </>
    )
}

export default WidgetTable;