import Navigation from "../../components/navigation/Navigation";
import Layout from "antd/es/layout/layout";
import { Flex, Collapse, Form, Input, Select, Breadcrumb, Checkbox, Col } from "antd";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import PublisherTable from "../../components/table/PublisherTable"
import { IoHome } from "react-icons/io5";
import axios from "axios";
import { useEffect, useState } from "react";
import Loading from "../../components/loading/Loading";
import { UserManager } from "../../utils/tools/user-manager/UserManager";
import BackButton from "../../components/navigation/BackButton";

const { Header } = Layout;

const apiUrl = window.AppConfig.API_ENDPOINT

const ContactInfo = ({data, form}) => {
    return (
        <Form
            layout="inline"
            name="contact-info"
            form={form}
            validateMessages={{required: false}}
            fields={[
                {
                    name: ["nome"],
                    value: data?.nome
                },
                {
                    name: ["cognome"],
                    value: data?.cognome
                },
                {
                    name: ["nomePartner"],
                    value: data?.nomePartner
                },
                {
                    name: ["telefono"],
                    value: data?.telefono
                },
                {
                    name: ["email"],
                    value: data?.email
                },
                {
                    name: ["emailPec"],
                    value: data?.emailPec
                },
                {
                    name: ["status"],
                    value: data?.status || "attivo"
                },
            ]}
        >
            <Flex vertical gap="large" style={{width: "100%"}}>
                <Flex>
                    <Form.Item
                        name="nome"
                        style={{width: "32%"}}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input placeholder="Nome" />
                    </Form.Item>

                    <Form.Item
                        name="cognome"
                        style={{width: "32%"}}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input placeholder="Surname" />
                    </Form.Item>

                    <Form.Item
                        name="nomePartner"
                        style={{width: "32%"}}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input placeholder="Nome Partner" />
                    </Form.Item>
                </Flex>
                <Flex>
                    <Form.Item
                        name="telefono"
                        style={{width: "25%"}}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input placeholder="Numero di telefono" />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        style={{width: "25%"}}
                        rules={[
                            {
                                required: true,
                                type: "email",
                                message: false
                            },
                        ]}
                    >
                        <Input type="email" disabled={!!data} placeholder="E-Mail" />
                    </Form.Item>
                    <Form.Item
                        name="emailPec"
                        style={{width: "25%"}}
                        rules={[
                            {
                                required: true,
                                type: "email",
                                message: false
                            },
                        ]}
                    >
                        <Input type="email" placeholder="PEC" />
                    </Form.Item>
                    <Form.Item
                        name="status"
                        style={{width: "20%"}}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select
                            optionFilterProp="label"
                            showSearch
                            options={[{value: "attivo", label: "Attivo"}, {value: "bozza", label: "Bozza"}, {value: "cancellato", label: "Cancellato"}]}
                            placeholder="Stato"
                            defaultValue={"attivo"}
                        />
                    </Form.Item>
                </Flex>
            </Flex>
        </Form>
    )
}

const InvoiceInfo = ({data, form}) => {
    return (
        <Form
            layout="inline"
            name="invoice-info"
            form={form}
            validateMessages={{required: false}}
            fields={[
                {
                    name: ["partitaIva"],
                    value: data?.partitaIva
                },
                {
                    name: ["ragioneSociale"],
                    value: data?.ragioneSociale
                },
                {
                    name: ["indirizzo"],
                    value: data?.indirizzo
                },
                {
                    name: ["cap"],
                    value: data?.cap
                },
                {
                    name: ["nazione"],
                    value: data?.nazione
                },
                {
                    name: ["iban"],
                    value: data?.iban
                },
            ]}
        >
            <Flex vertical gap="large" style={{width: "100%"}}>
                <Flex>
                    <Form.Item
                        name="partitaIva"
                        style={{width: "32%"}}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input placeholder="Partita IVA" />
                    </Form.Item>

                    <Form.Item
                        name="ragioneSociale"
                        style={{width: "32%"}}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input placeholder="Ragione Sociale" />
                    </Form.Item>

                    <Form.Item
                        name="indirizzo"
                        style={{width: "32%"}}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input placeholder="Indirizzo" />
                    </Form.Item>
                </Flex>
                <Flex>
                    <Form.Item
                        name="cap"
                        style={{width: "32%"}}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input placeholder="CAP" />
                    </Form.Item>

                    <Form.Item
                        name="nazione"
                        style={{width: "32%"}}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input placeholder="Nazione" />
                    </Form.Item>

                    <Form.Item
                        name="iban"
                        style={{width: "32%"}}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input placeholder="IBAN" />
                    </Form.Item>
                </Flex>
            </Flex>
        </Form>
    )
}

const Monetizzazione = ({data, sourceData, id, loggedUser}) => {
    const [selectedItems, setSelectedItems] = useState(sourceData?.fonti || []);
    const fonti = sourceData?.fonti;

    useEffect(() => {
        setSelectedItems(fonti || []);
    }, [fonti]);

    const handleChange = (val) => {
        const addedItems = val.filter(item => !selectedItems.includes(item));
        const removedItems = selectedItems.filter(item => !val.includes(item));

        if (loggedUser) {
            if (addedItems.length > 0) {
                axios.put(`${apiUrl}/api/v1/concessionarie/add-fonte`, {id: parseInt(id), fontiId: addedItems}, {
                    headers: {
                        'Authorization': `Bearer ${loggedUser.token.accessToken}`
                    }
                })
                .then(() => setSelectedItems(val))
            }
    
            if (removedItems.length > 0) {
                axios.put(`${apiUrl}/api/v1/concessionarie/remove-fonte`, {id: parseInt(id), fontiId: removedItems}, {
                    headers: {
                        'Authorization': `Bearer ${loggedUser.token.accessToken}`
                    }
                })
                .then(() => setSelectedItems(val))
            }
        }
        
    };

    return (
        <Checkbox.Group
            style={{ width: '100%' }}
            value={selectedItems}
            onChange={handleChange}
        >
            {
                data?.map((item) => {
                    return (
                        <Col key={`${item.value}-checkbox`} span={5}>
                            <Checkbox className="custom-checkbox" value={item.value}>{item.label}</Checkbox>
                        </Col>
                    );
                })
            }
        </Checkbox.Group>
    );
};

const NewDealership = () => {
    const [dataset, setDataset] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [trigger, setTrigger] = useState();
    const [form] = Form.useForm();
    const [loggedUser, setLoggedUser] = useState(null);
    const [sourceList, setSourceList] = useState([])

    useEffect(() => {
        const user = UserManager();
        if (user) {
            setLoggedUser(user);
        }
    }, []);

    useEffect(() => {
        if (loggedUser) {
            axios.post(`${apiUrl}/api/v1/init/list-fonti`, {}, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
            .then(response => {
                const data = response.data.map(item => ({value: parseInt(item.value), label: item.text}))
                setSourceList(data)
            })
            .catch(error => {
                console.error('Error:', error);
            });
        }
    }, [loggedUser])

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const dealershipId = searchParams.get('dealershipId');
    const publisherId = searchParams.get('id');

    const navigate = useNavigate();
    
    const {id} = useParams();

    useEffect(() => {
        form.resetFields();
        setDataset(null);  // Reset dataset when location changes
    }, [location]);

    useEffect(() => {
        if (loggedUser) {
            if (id !== "new" && !dealershipId) {
                const urlData = publisherId ? "publisher" : "concessionarie"
                axios.post(`${apiUrl}/api/v1/${urlData}/get-detail`, {id: parseInt(publisherId ?? id)}, {
                    headers: {
                        'Authorization': `Bearer ${loggedUser.token.accessToken}`
                    }
                })
                .then(response => {
                    setDataset(response.data)
                    setIsLoading(false)
                })
                .catch(error => {
                    console.error('Error:', error);
                    setIsLoading(false)
                });
            } else {
                setIsLoading(false)
            }
        }
    }, [trigger, loggedUser, id, publisherId])

    const handleSubmit = () => {
        if (loggedUser) {
            let request = {}
            let url
            if (id === "new") {
                request = {id: 0}
                url = "concessionarie"
            }
    
            if (id !== "new" && id !== "publisher") {
                request = {id: parseInt(id)}
                url = "concessionarie"
            }
    
            if (id === "publisher" && dealershipId) {
                request = {id: 0, parentPublisherId: parseInt(dealershipId)}
                url = "publisher"
            }
    
            if (id === "publisher" && publisherId) {
                request = {id: parseInt(publisherId), parentPublisherId: parseInt(dataset.parentPublisherId)}
                url = "publisher"
            }
    
            form.validateFields()
            .then(values => {
                axios.put(`${apiUrl}/api/v1/${url}/upsert`, {...request, ...values}, {
                    headers: {
                        'Authorization': `Bearer ${loggedUser.token.accessToken}`
                    }
                })
                .then(response => {
                    navigate(`/dealership`)
                })
                .catch(error => {
                    console.error('Error:', error);
                });
            })
            .catch(errorInfo => {
                console.log('Error:', errorInfo);
            });
        }
    }

    const pageTitle = () => {
        if (id === "new") {
            return "Nuova Concessionaria"
        }
        
        if (id === "publisher") {
            if (dealershipId) {
                return "Nuovo Publisher"
            } else if (dataset) {
                return `${dataset.nome} ${dataset.cognome}`
            }
        }

        if (dataset) {
            return dataset.ragioneSociale
        } else {
            return "Nuova concessionaria"
        }

    }

    return (
        <>
            <Loading visible={isLoading} />
            <Navigation>
                <Header>
                    <Flex justify="space-between" align="center">
                        <div>
                            <div className="main-title"> 
                                {pageTitle()}
                            </div>
                        </div>
                        <div className="filter-section">
                            <BackButton />
                            <div>
                                <button className="add-button" onClick={() => handleSubmit()}>Salva</button>
                            </div>
                        </div>
                    </Flex>
                </Header>
                <div className="main">
                    <Breadcrumb
                        separator=">"
                        items={[
                        {
                            href: "/",
                            title: (
                                <Flex gap="small" align="center">
                                    <IoHome />
                                    Home
                                </Flex>
                            ),
                        },
                        {
                            href: "/dealership",
                            title: 'Concessionarie',
                        },
                        {
                            title: id === "publisher" ? "Nuovo publisher" :
                            dataset ? dataset.ragioneSociale : "Nuova Concessionaria",
                            className: 'active-breadcrumb'
                        }
                        ]}
                    />
                    <Flex vertical gap="large">
                        <Collapse
                            expandIconPosition="end"
                            className="form-collapse"
                            items={[{ key: 'contact', label: 'Contact Info', children: <ContactInfo data={dataset} form={form} /> }]}
                            defaultActiveKey="contact"
                            expandIcon={({ isActive }) => isActive ? <img alt="active-accordion" src="/custom-icons/CloseAccordion.svg" /> : <img alt="active-accordion" src="/custom-icons/OpenAccordion.svg" />}
                        />
                        <Collapse
                            expandIconPosition="end"
                            className="form-collapse"
                            defaultActiveKey={dealershipId || publisherId || id ? "invoice" : "none"}
                            items={[{ key: 'invoice', label: 'Invoice Info', children: <InvoiceInfo data={dataset} form={form}/> }]}
                            expandIcon={({ isActive }) => isActive ? <img alt="active-accordion" src="/custom-icons/CloseAccordion.svg" /> : <img alt="active-accordion" src="/custom-icons/OpenAccordion.svg" />}
                        />
                        {
                            id && id !== "publisher" && id !== "new" && !dealershipId &&
                            <Collapse
                                expandIconPosition="end"
                                className="form-collapse"
                                defaultActiveKey={"monetizzazione"}
                                items={[{ key: 'monetizzazione', label: 'Fonti Monetizzazione', children: <Monetizzazione data={sourceList} sourceData={dataset} id={id} loggedUser={loggedUser}/> }]}
                                expandIcon={({ isActive }) => isActive ? <img alt="active-accordion" src="/custom-icons/CloseAccordion.svg" /> : <img alt="active-accordion" src="/custom-icons/OpenAccordion.svg" />}
                            />
                        }
                        {
                            dataset && id !== "publisher" &&
                            <>
                                <Flex justify="space-between" align="center">
                                    <h1>Publisher</h1>
                                    <button onClick={() => navigate(`/dealership/publisher?dealershipId=${id}`)} className="print-button">Aggiungi</button>
                                </Flex>
                                <PublisherTable data={dataset?.publishers} setTrigger={setTrigger} />
                            </>
                        }
                    </Flex>
                </div>
            </Navigation>
        </>
    )
}

export default NewDealership;
